export function modalEditarHandleFormChange({
  value,
  name,
  maxLength,
  data,
}: {
  value: any;
  name: string;
  maxLength: number;
  data: any;
}) {
  if (name === "note" && value.length > maxLength) {
    return {};
  }
  if (name === "idState") {
    const commonData = {
      paymentDate: null,
      SAPPaymentReferenceKey: "",
      SAPProposalFolio: "",
    };
    if (value === 1 || value === 3 || value === 5 || value === 6) {
      return {
        ...data,
        [name]: value,
        ...commonData,
      };
    } else if (value == 2) {
      return {
        ...data,
        [name]: value,
        note: data.note == null ? "" : data.note,
      };
    } else if (value === 3 && data.note == null) {
      return {
        ...data,
        [name]: value,
        note: "",
        ...commonData,
      };
    }
  } else {
    return {
      ...data,
      [name]: value,
    };
  }
}
