import { Button } from '@mui/material'
import { utils, writeFile } from 'xlsx'
import { useState } from 'react';
import { client } from '../../../configApollo/config';
import { GetReportRenditionsByTribDoc } from '../../../services/getReportRenditionsByTribDoc';
import { getFormattedDate } from '../../../utils/getFormatDate';
import { defaultContent } from '../../../utils/constDefault';
import Toast from '../../../components/ui-components/Toast/Toast';
import downloadIcon from "../../../assets/icons/exportGreen.svg";

interface ReportMedicalRenditionProps {
    disabled: boolean;
    holding: string;
    stateRendition: string;
    dateInit: Date | null;
    dateEnd: Date | null;
    folioRendition: string;
    folioBono: string;
    folioTributary: string;
    numRendition: number;
    searchT: string;
    orderBy: string;
    typeOrder: string;
}

export const ReportMedicalRendition: React.FC<ReportMedicalRenditionProps> = ({ disabled, holding, stateRendition, dateInit, dateEnd, folioBono, folioRendition, folioTributary, searchT, orderBy, typeOrder }) => {
    const [contentAlert, setContentAlert] = useState<any>(defaultContent);
    const [alert, setAlert] = useState<boolean>(false);

    const openToast = (
        isSuccess: boolean,
        content: string,
        typeAlert: string,
        styleAlert: object
    ) => {
        setContentAlert({ isSuccess, content, typeAlert, styleAlert });
        setAlert(true);
    };

    const handleExcelGenerate = async () => {
        try {
            const data: any = await handleRefreshRenditionsBonos(1, [])
            if (!data) {
                openToast(
                    false,
                    "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                    "top",
                    { marginTop: "100px" }
                );
            } else {
                const book = utils.book_new();
                const sheet = utils.json_to_sheet(data);

                const name = `ReporteBusquedaRendiciones_${getFormattedDate(new Date())}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Reporte Bonos');
                writeFile(book, name);
                openToast(
                    true,
                    "Descarga del reporte exitosa. Acceder al reporte en carpeta de Descargas de su sistema local.",
                    "top",
                    { marginTop: "100px" }
                );
            }
        } catch (error) {
            openToast(false, "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                "top",
                { marginTop: "100px" }
            );
        }
    }

    const handleRefreshRenditionsBonos = async (page: number, allData = []) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await client.query({
                    query: GetReportRenditionsByTribDoc,
                    variables: {
                        renditionFilters: {
                            renditionFolio: folioRendition === "" || folioRendition === "0" ? null : Number(folioRendition),
                            rutBilling: holding === "" || holding === "0" || holding === "todos" ? null : holding,
                            renditionStatus: stateRendition === "" || stateRendition === "0" ? null : stateRendition,
                            startDate: dateInit ? getFormattedDate(dateInit) : null,
                            endDate: dateEnd ? getFormattedDate(dateEnd) : null,
                            bonoFolio: folioBono === "" || folioBono === "0" ? null : Number(folioBono),
                            tributaryFolio: folioTributary === "" || folioTributary === "0" ? null : String(folioTributary),
                            searchText: (searchT) ? searchT : null
                        },
                        page: page,
                        perPage: 50,
                        orderBy: `${orderBy} ${typeOrder}`,
                        isCCP: true
                    }
                });

                const { data } = result.data.rendByFiltersReport;

                if (data) {
                    const preData = data.map(({ __typename, ...rest }: any) => rest);
                    let newData = allData.concat(preData);
                    if (data.length === 50) {
                        handleRefreshRenditionsBonos(page + 1, newData).then(resolve)
                    } else {
                        resolve(newData);
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    };

    return (
        <>
            <Toast
                openAlert={alert}
                setOpenAlert={setAlert}
                contentAlert={contentAlert}
            />
            <Button
                variant="outlined"
                disabled={disabled}
                onClick={handleExcelGenerate}
                startIcon={<img src={downloadIcon} alt='Descargar reporte' />}
                sx={{ p: "15px 40px" }}
                data-testid="btn-reporte">
                Reporte
            </Button>
        </>

    )
}
