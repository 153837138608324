import { Box, CssBaseline } from '@mui/material';
import React from 'react'
import Header from './components/header/Header';
import { SideBar } from './components/SideBar/SideBar';
import { Main } from './components/UI/MainContent/mainContent';
import RoutesConfig from './routes';


export const App = () => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CssBaseline />
            <Header />

            <SideBar open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />

            <Main open={open}>
                <RoutesConfig />
            </Main>

        </Box>
    )
}
