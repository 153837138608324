import { useState } from "react"
import { ApolloError, useLazyQuery, useMutation } from "@apollo/client";
import { queryGetTransactionsErrors,
         queryDownloadFile, 
         queryGetSAPErrors, 
         mutationDeleteFile,
         mutationSolicitud,
         mutationRechazar,
         mutationRollbackStatus } from "../../services/perfilamiento";
import { downloadTransactionsErrorsJSON,downloadSAPErrorsJSON, downloadFromBase64 } from "../../utils";
export const useTableDocumentos = (searchFile:any) => {
  
    const [idNumOperacion, setIdNumOperacion] = useState<number | null>(null)
    const [rechazo, setRechazo] = useState({logUsuarioRechazo: "", glosaCausalRechazo: "",})
    const [ fnGetTransactionsErrors, { loading: loadingTransactionsErrors }] = useLazyQuery(queryGetTransactionsErrors, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });
    const [ fnGetSAPErrors, { loading: loadingSAPErrors }] = useLazyQuery(queryGetSAPErrors, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });
    const [ fnDownloadFile] = useLazyQuery(queryDownloadFile, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });
    const [fnDeleteFile, { loading: loadingDeleteFile }] = useMutation(mutationDeleteFile);
    const [openAlertTransactionsErrors, setOpenAlertTransactionsErrors] = useState<boolean>(false)
    const [toastTransactionsErrors, setToastTransactionsErrors] = useState({content: "", isSuccess: false,})
    const [openToastSolicitud, setOpenToastSolicitud] = useState<boolean>(false)
    const [toastSolicitud, setToastSolicitud] = useState({content: "", isSuccess: false,})
    const [fnSolicitudService, { loading: loadingSolicitud , error: errorSolicitud}] = useMutation(mutationSolicitud);
    const [fnRollbackStatus, { loading: loadingRollbackStatus, error: errorRollbackStatus}] = useMutation(mutationRollbackStatus);
    const [fnRechazarService] = useMutation(mutationRechazar);
    const [openModal, setOpenModal] = useState(false)
    const [openModalRechazo, setOpenModalRechazo] = useState(false)
    const [disable, setDisable] = useState(false)
    const handleOnClickGetTransactionsErrors = (event: any) => {

        fnGetTransactionsErrors({ variables: { idRegistro:event.idRegistro }})
          .then((response:any) => {
            const { data: dataResponse } = response
            downloadTransactionsErrorsJSON(dataResponse,event.idRegistro)
            setOpenAlertTransactionsErrors(true);
            setToastTransactionsErrors({content: "Se ha generado su documento exitosamente", isSuccess: true});
            
          })
          .catch((error) => {
            setOpenAlertTransactionsErrors(true);
            setToastTransactionsErrors({content: "No se ha podido generar el documento, por favor intente nuevamente", isSuccess: false});
            //console.log(error)
          })
    };


    const handleOnClickGetSAPErrors = (event: any) => {

      fnGetSAPErrors({ variables: { idRegistro:event.idRegistro }})
        .then((response:any) => {
          const { data: dataResponse } = response
          downloadSAPErrorsJSON(dataResponse,event.idRegistro)
          setOpenAlertTransactionsErrors(true);
          setToastTransactionsErrors({content: "Se ha generado su documento exitosamente", isSuccess: true});
          
        })
        .catch((error) => {
          setOpenAlertTransactionsErrors(true);
          setToastTransactionsErrors({content: "No se ha podido generar el documento, por favor intente nuevamente", isSuccess: false});
          //console.log(error)
        })
    };


    const handleOnClickDeleteFile = (idRegistro: number) => {
      setOpenModal(true)
      setIdNumOperacion(idRegistro)
    };


    const handleDeleteFile = () => {
      setToastTransactionsErrors({content: "Eliminando documento", isSuccess: true});
      setOpenAlertTransactionsErrors(true);
      fnDeleteFile({ variables: { idRegistro:idNumOperacion }})
      .then((response:any) => {
        setOpenAlertTransactionsErrors(false);
        setToastTransactionsErrors({content: "Documento eliminado", isSuccess: true});
        setOpenAlertTransactionsErrors(true);
      })
      .catch((error) => {
        setOpenAlertTransactionsErrors(false);
        setToastTransactionsErrors({content: "Su documento no se pudo eliminar", isSuccess: false});
        setOpenAlertTransactionsErrors(true);
        //console.log(error)
      })
      .finally(() =>{
        setOpenModal(false)
        setTimeout(
          () =>searchFile(), 
          500
        ); 
      })
      
    };

    const handleOnClickRechazar = (idRegistro: number) => {
      setOpenModalRechazo(true)
      setIdNumOperacion(idRegistro)
    };

    const handleRechazar = () => {
      setToastTransactionsErrors({content: "Procesando documento", isSuccess: true});
      setOpenAlertTransactionsErrors(true);
      const {logUsuarioRechazo, glosaCausalRechazo} = rechazo
      fnRechazarService({ variables: { idRegistro:idNumOperacion, logUsuarioRechazo: logUsuarioRechazo, glosaCausalRechazo: glosaCausalRechazo  }})
      .then((response:any) => {
        setOpenAlertTransactionsErrors(false);
        setToastTransactionsErrors({content: "Documento rechazado", isSuccess: true});
        setOpenAlertTransactionsErrors(true);
      })
      .catch((error) => {
        setOpenAlertTransactionsErrors(false);
        setToastTransactionsErrors({content: "El documento no se pudo rechazar", isSuccess: false});
        setOpenAlertTransactionsErrors(true);
        //console.log(error)
      })
      .finally(() =>{
        setOpenModalRechazo(false)
        setTimeout(
          () =>searchFile(), 
          1000
        ); 
      })  
    };

    const handleOnClickSolicitud = (event: any) => {
      setDisable(true);
      setToastTransactionsErrors({
        content: "Su documento se esta enviando",
        isSuccess: true,
      });
      setOpenAlertTransactionsErrors(true);
      fnSolicitudService({ variables: { idRegistro: event.idRegistro, logUsuarioUploader: event.logUsuarioUploader }, })
        .then((response: any) => {
          setOpenAlertTransactionsErrors(false);
          setToastSolicitud({ content: "Documento enviado", isSuccess: true });
          setOpenToastSolicitud(true);
        })
        .catch((error: ApolloError) => {
          setOpenAlertTransactionsErrors(false);
          if (error.message === "Failed to fetch") {
            rollbackStatus(event.idRegistro);
            setToastSolicitud({
              content:
                "Hemos tenido un error de sincronización a SAP, por favor inténtelo más tarde",
              isSuccess: false,
            });
          } else {
            setToastSolicitud({
              content: "Su documento no se pudo enviar",
              isSuccess: false,
            });
          }
          setOpenToastSolicitud(true);
        })
        .finally(() => {
          setTimeout(() => {
            searchFile();
            setDisable(false);
          }, 2000);
        });
    };

    const rollbackStatus = (idRegistro: number) => {
      fnRollbackStatus({ variables: {idRegistro}})
      .then((response: any) => {
        console.log('rollbackStatus ok', response);
      })
      .catch((error: ApolloError) => {
        console.log('rollbackStatus error', error);
      })
    }

    const handleOnClickDownloadFile = (event: any) => {
      setOpenAlertTransactionsErrors(true);
      setToastTransactionsErrors({content: "Su documento se esta descargando", isSuccess: true});
      fnDownloadFile({ variables: { idNumOperacion: event.idRegistro }})
        .then((response:any) => {
          const { contentType, base64, nombreArchivo } = response.data.downloadFile.file;
          downloadFromBase64(base64, nombreArchivo, contentType);
          setOpenAlertTransactionsErrors(false);
          setOpenAlertTransactionsErrors(true);
          setToastTransactionsErrors({content: "Documento descargado", isSuccess: true});
          
        })
        .catch((error) => {
          setOpenAlertTransactionsErrors(false);
          setOpenAlertTransactionsErrors(true);
          setToastTransactionsErrors({content: "Su documento no se pudo descargar", isSuccess: false});
          //console.log(error)
        })
    };
    
    return{
        fnGetTransactionsErrors,
        loadingTransactionsErrors,
        openAlertTransactionsErrors,
        setOpenAlertTransactionsErrors,
        toastTransactionsErrors,
        setToastTransactionsErrors,
        handleOnClickGetTransactionsErrors,
        handleOnClickSolicitud,
        handleOnClickGetSAPErrors,
        loadingSAPErrors,
        openModal,
        setOpenModal,
        handleOnClickDeleteFile,
        handleDeleteFile,
        handleOnClickRechazar,
        handleRechazar,
        openModalRechazo,
        setOpenModalRechazo,
        setRechazo,
        rechazo,
        loadingDeleteFile,
        loadingSolicitud,
        openToastSolicitud,
        setOpenToastSolicitud,
        toastSolicitud,
        setToastSolicitud,
        handleOnClickDownloadFile,
        disable,
        setDisable 
    }
}
