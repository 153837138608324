import { Box, Divider, styled, Tooltip, TooltipProps, Typography } from '@mui/material';
import { dataRowTooltip, titleRowTooltip } from '../../UI/Tooltip/tooltip';

export const ContentTooltip = (props:any) => {

    let usuario = props.dataTooltip.logUsuarioCentralizador.split('|')
    const rowName  = ['Num. Operación','Total asientos procesados','Total Mov. Contables','Total asientos correctos (SAP)', 'Total asientos con Error (SAP)','Usuario','Canal Origen','Causal Rechazo'];
    const rowValue = [ 
        `${props.dataTooltip.idNumOperacion}`,
        `${props.dataTooltip.totalAsientosContablesSAP}`,
        `${props.dataTooltip.totalRegistrosDetalle}`, 
        `${props.dataTooltip.totalAsientosOKSAP}`, 
        `${props.dataTooltip.totalAsientosERRORSAP}`,  
        `${usuario[0]}`,
        `${props.dataTooltip.canalOrigen}`,
        `${props.dataTooltip.glosaCausalRechazo}`
    ];

    return (
        <Box display="flex" flexDirection="column" padding="16px 20px">
           
           
              {
                rowName.map((value, i) => (
                    <Box key={i}>
                        <Box display="flex" padding="6px 8px">
                            <Typography width={'200px'} sx={titleRowTooltip}>{value}</Typography>
                            {(rowValue[i] !== "null" && rowValue[i] !== undefined ) 
                            ? <Typography width={'280px'} sx={dataRowTooltip}>{rowValue[i]}</Typography>
                            : <Typography width={'280px'} sx={dataRowTooltip} className="disabled-text">Pendiente</Typography>}
                            
                        </Box>
                        {(i + 1) !== rowName.length ? <Divider></Divider> : null}
                       
                    </Box>
                ))    
            }           
        </Box>
    )
}

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#4d5d68',
        color: 'white',
        border: '1px solid #dadde9',
        maxWidth: '833px',
        maxHeight: '580px',
        borderRadius: '8px',
        boxShadow: '0px 6px 20px #B2B9CF',
        fontSize: '16px'
    },
    '& .MuiTooltip-arrow': {
        fontSize: 28,
        '&::before': {
            backgroundColor: '#4d5d68',
            boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)',
            // height: '27px',
        }
    },
});

export const StyledTooltipTableEmision = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    '& .MuiTooltip-tooltip': {
        backgroundColor: '#FFF',
        color: 'white',
        border: '1px solid #dadde9',
        maxWidth: '833px',
        maxHeight: '580px',
        borderRadius: '8px',
        boxShadow: '0px 6px 20px #B2B9CF',
        fontSize: '16px'
    },
    '& .MuiTooltip-arrow': {
        fontSize: 28,
        '&::before': {
            backgroundColor: '#FFF',
            boxShadow: '0px 2px 5px rgba(201, 207, 227, 0.75)',
            // height: '27px',
        }
    },
});