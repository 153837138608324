import { InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from './../../../theme/themeConfig';


const CustomSelectInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '15px 0px 16px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderColor: '#5D5FEF',
            borderRadius: '8px',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}));

const CustomTableSelect = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #BCC2C6',
        fontSize: 16,
        color: '#364855',
        padding: '15px 0px 16px 16px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        WebkitAppearance: 'none',
        letterSpacing: '0.0120588em',
        '&:focus': {
            borderColor: '#5D5FEF',
            borderRadius: '8px',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
}));

const ITEM_HEIGHT = 48;

const menuSelectStyle = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4,
            borderRadius: 8,
        },
        sx: {
            '&.MuiPaper-root::-webkit-scrollbar': {
                width: '5px'
            },
            '&.MuiPaper-root::-webkit-scrollbar-track': {
                background: 'white',
                borderRadius: '100px',
                margin: '10px',
            },
            '&.MuiPaper-root::-webkit-scrollbar-thumb': {
                background: '#BCC2C6',
                borderRadius: '100px',
            },
        }
    },
};

const menuItemStyle = {
    fontWeight: '700',
    letterSpacing: '0.0120588em',
    color: '#364855',
    borderBottom: '1px solid #E9EBEC',
    height: '48px',
    textTransform: 'capitalize'
}

const menuHoldingStyle = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4,
            borderRadius: 8,
        },
        sx: {
            '&.MuiPaper-root::-webkit-scrollbar': {
                width: '5px'
            },
            '&.MuiPaper-root::-webkit-scrollbar-track': {
                borderRadius: '100px',
                margin: '10px',
            },
            '&.MuiPaper-root::-webkit-scrollbar-thumb': {
                background: '#BCC2C6',
                borderRadius: '100px',
            },
            '&.MuiPopover-paper':{
                [theme.breakpoints.up('lg')]: {
                    width: '39.5%',
                    marginLeft: '0.1%',
                    //backgroundColor: '#592E83',
                },
                [theme.breakpoints.up('md')]: {
                    width: '10%',
                    marginLeft: '6%',
                    //backgroundColor: '#B27C66',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '5%',
                    marginLeft: '15%',
                    //backgroundColor: '#908C8B',
                },
            }
        }
    },
    

}

export {
    CustomSelectInput,
    menuSelectStyle,
    menuItemStyle,
    menuHoldingStyle,
    CustomTableSelect
}