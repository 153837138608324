import { gql } from "@apollo/client"

export const UpdateNote = gql`
mutation UpdateNote($id: Int, $idState: Int, $note: String, $SAPPaymentReferenceKey: String, $SAPProposalFolio: String, $paymentDate: Date, $providerUser: String) {
    updateNote(id: $id, idState: $idState, note: $note, SAPPaymentReferenceKey: $SAPPaymentReferenceKey, SAPProposalFolio: $SAPProposalFolio, paymentDate: $paymentDate, providerUser: $providerUser){
        estado
        mensaje
    }
}
`;

export const UpdateNoteMulti = gql`
mutation UpdateNoteMulti($id: [Int], $idState: Int, $note: String, $SAPPaymentReferenceKey: String, $SAPProposalFolio: String, $paymentDate: Date, $providerUser: String) {
    updateNoteMulti(id: $id, idState: $idState, note: $note, SAPPaymentReferenceKey: $SAPPaymentReferenceKey, SAPProposalFolio: $SAPProposalFolio, paymentDate: $paymentDate, providerUser: $providerUser){
        estado
        mensaje
    }
}
`;