import { styled } from '@mui/material/styles';
import TableCell from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  '&.MuiTableCell-head': {
    padding: '10px 14px',
  },
  '&.MuiTableCell-body': {
    padding: '10px 14px',
    overflow: 'hidden!important',
    whiteSpace: 'nowrap!important',
  }
}));


const stickyBodyRight = (right: string) => ({
  position: "sticky",
  background: "white",
  right: right
});
const stickyBodyRightF = (right: string) => ({
  position: "sticky",
  background: "white",
  right: right,
  border: 'none'
});
const stickyBodyRightShadow = (right: string) => ({
  boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
  position: "sticky",
  background: "white",
  right: right,
  
});

const stickyBodyRightShadowF = (right: string) => ({
  boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
  position: "sticky",
  background: "white",
  border: 'none',
  right: right,
  
});

const stickyBodyLeft = (left: string) => ({
  position: "sticky",
  background: "white",
  left: left,
  boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
});


const stickyBodyRightHead = (right: string) => ({
  position: "sticky",
  background: "#F8F9F9",
  color: '#364855',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.0120588em',
  right: right,

});
const stickyBodyRightHeadShadow = (right: string) => ({
  position: "sticky",
  background: "#F8F9F9",
  color: '#364855',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.0120588em',
  right: right,
  boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',

});

const stickyRightHeadShadow = (right: string) => ({
  position: "sticky",
  background: "#F8F9F9",
  color: '#364855',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.0120588em',
  right: right,
  boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
});

const stickyRightShadow = (right: string) => ({
  position: "sticky",
  background: "white",
  right: right,
  boxShadow: 'inset 5px 0px 5px 0px rgb(201 207 227 / 35%)',
});

const stickyBodyLeftHead = (left: string) => ({
  position: "sticky",
  background: "#F8F9F9",
  left: left,
  boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
});
const styledModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "530px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px #364855",
  outline: 'none'
};

const typographyModal = {
  color: "#364855",
  lineHeight: "20px",
  fontSize: "16px",
  paddingBottom: "10px",
  paddingTop: "24px",
  letterSpacing: "0.0120588em",
};

const styleHead = {
  backgroundColor: '#F8F9F9',
  color: '#364855',
}

const stickyHead = {
  position: "sticky",
  backgroundColor: "#F8F9F9",
  color: '#364855',
  zIndex: 999,
  left: 0, 
  borderRight: '#F8F9F9!important',
  boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
}

const normalHead = {
}

const stickyCol = {
  position: "sticky",
  backgroundColor: "#FFF",
  color: '#364855',
  borderRight: '0px solid #c9cfe35c',
  zIndex: 999,
  left: 0, 
  boxShadow: 'inset -5px 0px 5px 0px rgb(201 207 227 / 35%)'
}

const overCol = {
  position: "sticky",
  marginRight: '0px',
  zIndex: 2
}

const penUltimOverCol = {
  position: "sticky",
  marginRight: '50px',
  zIndex: 2

}

const styleTextoMontos = {color: '#55798F', fontSize: '13px', };



export {
  StyledTableCell,
  styledModal,
  typographyModal,
  stickyBodyRight,
  stickyBodyLeft,
  styleHead,
  stickyBodyRightHead,
  stickyBodyRightShadow,
  stickyBodyRightShadowF,
  stickyBodyRightHeadShadow,
  stickyBodyRightF,
  styleTextoMontos,
  stickyRightShadow,
  stickyRightHeadShadow,
  stickyBodyLeftHead,
  stickyHead,
  stickyCol,
  normalHead,
  penUltimOverCol,
  overCol
}
