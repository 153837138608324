
const titleTooltip = {
    marginBottom: '16px',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

const titleRowTooltip = {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

const dataRowTooltip = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

export {
    titleTooltip,
    titleRowTooltip,
    dataRowTooltip
}