import {
    CircularProgress,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from "@mui/material";
import icon_warningOrange from "../../assets/icons/iconWarning.svg";
import iconError from "../../assets/icons/iconError.svg"; 
import { CustomModal, CustomModalTitle } from "../UI/Modal/customModal";
import { modalText, modalTitle } from "../ui-components/Typography/typography";
import { PrimaryButton, SecondaryButton } from "../UI/Button/Button";

type WarningModalProps = {
    onOpen: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    disabled?: boolean | undefined;
    title: string;
    subTitle?: any;
    primaryButton: string;
    secondaryButton?: string;
    loading?: boolean;
    noSecondAction?: boolean;
    headWarning?: boolean;
    contentText?: JSX.Element;
    loadingWidth?: string;
    errorModal?: boolean;
    separateButtons?: boolean;
};

export const WarningModalCustom = ({
    onOpen,
    onClose,
    onConfirm,
    disabled,
    title,
    subTitle,
    primaryButton,
    secondaryButton,
    noSecondAction,
    loading,
    headWarning,
    contentText,
    loadingWidth,
    errorModal,
    separateButtons
}: WarningModalProps) => {
    return (
        <CustomModal
            data-testid='modal-warning'
            fullWidth
            maxWidth="sm"
            open={onOpen}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                },
            }}
        >
            {headWarning ? (/**acá si */
                <>
                    <CustomModalTitle id="warning" onClose={onClose}>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        {errorModal ? (
                            <img
                                alt="error"
                                src={iconError}
                                style={{ marginBottom: "16px" }}
                            />
                        ) :
                            <img
                                alt="alert"
                                src={icon_warningOrange}
                                style={{ marginBottom: "16px" }}
                            />}

                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 5px 0" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent={separateButtons ? "space-between" : "center"}
                        >
                            {noSecondAction ? null :
                                <SecondaryButton
                                    onClick={onClose}
                                    disabled={loading || disabled}
                                    sx={{ marginRight: "24px", p: "15px 42px" }}
                                >
                                    {secondaryButton}
                                </SecondaryButton>
                            }
                            {loading ? (
                                <PrimaryButton
                                    sx={{
                                        width: loadingWidth ? loadingWidth : "175px",
                                        height: "50px",
                                    }}
                                >
                                    <CircularProgress color="inherit" size={"20px"} />
                                </PrimaryButton>
                            ) : (
                                <PrimaryButton onClick={onConfirm}>
                                    {primaryButton}
                                </PrimaryButton>
                            )}

                        </Grid>
                    </DialogActions>
                </>
            ) : (/**acá no */
                <>
                    <CustomModalTitle id="warning" onClose={onClose}>
                        <Typography sx={modalTitle} paddingTop="16px">
                            {title}
                        </Typography>
                    </CustomModalTitle>

                    <DialogContent
                        dividers
                        sx={{ textAlign: "center", pl: "24px", pr: "24px" }}
                    >
                        <img
                            alt="img"
                            src={icon_warningOrange}
                            style={{ marginTop: "20px", marginBottom: "16px" }}
                        />
                        <Typography sx={modalText}>{subTitle}</Typography>
                        {contentText}
                    </DialogContent>

                    <DialogActions sx={{ textAlign: "center", margin: "5px 0 15px 0" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {noSecondAction ? null :
                                <SecondaryButton
                                    sx={{ minWidth: "175px", marginRight: "24px" }}
                                    onClick={onClose}
                                    disabled={loading}
                                >
                                    {secondaryButton}
                                </SecondaryButton>
                            }
                            {loading ? (
                                <PrimaryButton sx={{ width: "175px", height: "50px" }}>
                                    <CircularProgress color="inherit" size={"20px"} />
                                </PrimaryButton>
                            ) : (
                                <>
                                    <PrimaryButton
                                        sx={{ minWidth: "175px" }}
                                        disabled={disabled}
                                        onClick={onConfirm}
                                    >
                                        {primaryButton}
                                    </PrimaryButton>
                                </>
                            )}
                        </Grid>
                    </DialogActions>
                </>
            )}
        </CustomModal>
    );
};