import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react' 
import { issuedStatusStyle, paidStyle, requestedStylePrcesado } from '../ui-components/box/Boxs';

export const StatePaymentRequest = ({state, textState}: any) => {
    const [typeState, setTypeState] = useState<any>(null)
    //const [textState, setTextState] = useState<string>("")
    useEffect(() => {
        //setTextState(state)
        if (state ===  8 || state ===  9) { //procesado con exito o error = SAP
            setTypeState(requestedStylePrcesado)            
        }
        else if (state ===  13) { //revisada
            setTypeState(paidStyle)            
        }
        else { // 12 = en prop u otro
            setTypeState(issuedStatusStyle)
            textState = 'En propuesta'
        }
    }, [state])
  return (
    <Box sx={typeState}>
        <Typography variant="h6" fontSize="12px">
            {textState}
        </Typography>
    </Box>
  )
}
