import { gql } from "@apollo/client"

export const GetReportRenditionsByTribDoc = gql`
query rendByFiltersReport($renditionFilters: renditionFilters, $page: Int, $perPage: Int, $orderBy: String, $isCCP: Boolean){
    rendByFiltersReport(renditionFilters: $renditionFilters, page: $page, perPage: $perPage, orderBy: $orderBy, isCCP: $isCCP){
        estado
        mensaje
        totalRecords
        countRenditions
        data {
            idRendicion
            idEstado
            descEstado
            fechaEstado
            causalRechazo
            fechaSolicitud
            fechaProbablePago
            rutFacturador
            razonSocialFacturador
            montoTotalFacturado
            diferenciaTotalAjusteSencillo
            montoTotalRendido
            montoTotalAPago
            totalBonosRendidos
            idDocCobroTributario
            tipoDocCobro
            descDocumentType
            folioDocTributario
            fechaDocTributario
            montoNeto
            montoExento
            montoImpuesto
            montoTotal
            folioBonoRendido
            fechaAtencionBono
            canalBono
            leyUrgencia
            preventivo
            folioCuentaMedica
            tipoCobertura
            tipoAtencion
            totalMontoPrestacion
            totalMontoBonificado
            totalMontoCopago
            totalMontoRendido
            totalMontoPago
        }
        
    }
}
`;