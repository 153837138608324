import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { blackBold3, detailText,titleBox } from "../../components/ui-components/Typography/typography";
import { boxCardNP } from "../../components/ui-components/box/Boxs";
import Breadcrum from "../../components/ui-components/breadcrum/customBreadcrum";
import { renditionProcess } from "../../utils/const";
import { useEffect, useState } from 'react';
import Toast from "../../components/ui-components/Toast/Toast";
import { CustomSelect } from "../../components/ui-components/customSelect/customSelect";
import ActIcon from "../../assets/icons/updatearrow.svg";
import Lupa from "../../assets/icons/lupaInput.svg";
import TablePaymentRequest from "../../components/solicitudPago/tableSolicitudPago";
import { CustomInput } from "../../components/UI/Input/input";


export default function PaymentRequest() {
    const [idTypeProcess, setIdTypeProcess] = useState<number>(0)
    const [alert, setAlert] = useState(false);
    const [contentAlert, setContentAlert] = useState<any>("");
    const [refreshData, setRefreshData] = useState(0)
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    const [debouncedQuery, setDebouncedQuery] = useState("");

    const [orderBy, setOrderBy] = useState('id'); 
    const [searchTextbox, setSearchTextbox] = useState('');

    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState({
        estado: null,
        itemOperacion: null,
        idNumOperacion: []
    });

    const handleSelectChange = (event: { target: { value: any; }; }) => {
        setSelectedItems([])
        setSearchTextbox('');
        const newValue = event.target.value;
        setRefreshData(0)
        setIdTypeProcess(newValue);
    };

    const handleUpdateButton = () => {
        setRefreshData(refreshData + 1)
        setSearchTextbox('');
    }

    const handleSearchText = (event: { target: { value: any; }; }) => {
        setSearchTextbox(event.target.value)
        
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedQuery(searchTextbox);
        }, 2000); 
    
        return () => {
        clearTimeout(debounceTimer);
        };
    }, [searchTextbox]);

    useEffect(() => {
        setRefreshData(refreshData + 1)
        //eslint-disable-next-line
    }, [debouncedQuery]);

    return (
        <>           
            <Toast
                openAlert={alert}
                setOpenAlert={setAlert}
                contentAlert={contentAlert}
            />
            <Box pt="30px" mb="5% !important" role="body">
                <Typography width="86%" m="auto" variant="h4" pb="14px" pt="24px">
                    Solicitud de pagos
                </Typography>
                <Box width="86%" m="auto">
                    <Breadcrum
                        back1="Inicio"
                        link1="/"
                        escritorio
                        now="Solicitud de pagos"
                    />
                </Box>

                <Box sx={boxCardNP} mt="10px !important">
                    <Grid container p="24px">
                        <Grid item xs={12} pr="5%" pb="16px">
                            <Typography sx={titleBox}>Filtrar solicitudes de pago</Typography>
                        </Grid>
                        <Grid item xs={4} pr="39px">
                            <Typography sx={detailText} pb="4px">
                                Proceso
                            </Typography>
                            <CustomSelect
                                value={idTypeProcess}
                                data={renditionProcess}
                                titulo={"Seleccione"}
                                onChange={handleSelectChange}
                            />
                        </Grid>
                        <Grid item xs={8} pr="1%" pt="32px" display={"flex"} justifyContent={"flex-end"}>
                            
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container p="24px">
                        <Grid item xs={12} pr="5%">
                            <Typography sx={blackBold3}>Detalle de solicitudes de pago</Typography>
                        </Grid>
                        <Grid item xs={8} md={9} lg={10} pt="15px">
                            <CustomInput
                                onChange={handleSearchText}
                                value={searchTextbox}//
                                placeholder={`Seleccione criterios de búsqueda en la tabla para resultados más específicos.`}
                                fullWidth
                                InputProps={{
                                    endAdornment: (<IconButton><img src={Lupa} alt="buscar" /></IconButton>),
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} md={3} lg={2} pt="15px" display={"flex"} justifyContent={"center"}>
                            <Button onClick={handleUpdateButton} color="primary" variant="outlined" startIcon={<img src={ActIcon} alt="act icon" />}>
                                Refrescar
                            </Button>
                        </Grid>
                    </Grid>

                    <TablePaymentRequest idTypeProcess={idTypeProcess} refreshData={refreshData} selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems} categoriaSeleccionada={categoriaSeleccionada} setCategoriaSeleccionada={setCategoriaSeleccionada}
                        setSearchTextbox={setSearchTextbox} searchTextbox={searchTextbox} 
                        orderBy={orderBy} setOrderBy={setOrderBy} />
                </Box>

                <Box sx={boxCardNP} mt="10px !important">
                </Box>
            </Box>
        </>
    )
}