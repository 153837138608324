import { Box, Button, Grid, Typography, Divider } from "@mui/material";
import WhitePencil from "../../assets/icons/whitePencil.svg";
export const title = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.0120588em",
  color: "#364855",
};

export const regis = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  letterSpacing: "0.0120588em",
  color: "#4D5D68",
  display: "inline",
};

// const ter = {
//     fontFamily: "Rubik",
//     fontStyle: "normal",
//     fontWeight: 400,
//     fontSize: "14px",
//     lineHeight: "16px",
//     letterSpacing: "0.0120588em",
//     color: "#63717B",
//     display: "inline",
// };

export const BoxStyle = {
  backgroundColor: "#857BE2",
  borderRadius: "8px",
  width: "546px",
  zIndex: 9999,
  margin: " 0 auto",
};
const LetterStyle = {
  fontFamily: "Interstate !important",
  fontSize: "16px !important",
  fontWeight: "700 !important",
  lineHeight: "20px !important",
  letterSpacing: "0.0021em  !important",
  textAlign: "center !important",
  color: "#FFFFFF !important",
};

// const boxPrincipal = {
//   position: "relative",
//   alignItems: "center",
//   textAlign: "center",
//   display: "flex",
//   width: "100%",
//   top: "-67px",
// };

const ButtonSelect = (props: any) => {
  const { setOpen, checkCount } = props;
  return (
    <Box
      sx={{
        position: "fixed",
        alignItems: "center",
        textAlign: "center",
        display: "flex",
        top: "80%",
        left: "38%",
        zIndex: 9999,
      }}
    >
      <Box sx={BoxStyle}>
        <Grid container sx={{flexDirection: 'row'}}>
          <Box
            textAlign={"right"}
            sx={{ width: '40%', placeSelf: "center", float: "right" }}
          >
            <Typography sx={LetterStyle} paddingLeft="40%">
              Seleccionado: {checkCount}
            </Typography>
          </Box>
          <Box pt="10px" pb="10px" sx={ {width: '10%'}}>
            <Divider
              sx={{  borderColor: "#FFFFFF !important" }}
              orientation="vertical"
            />
          </Box>
          <Box sx={ {width: '40%'}}
          >
            <Button color="inherit" onClick={() => setOpen()}>
              <img src={WhitePencil} alt="Cambiar estado" />{" "}
              <Typography sx={LetterStyle} paddingLeft={"12px"}>
                {" "}
              Cambiar estado
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default ButtonSelect;
