import es from "date-fns/locale/es";
import { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, Divider, IconButton, Stack, InputAdornment } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { useLazyQuery } from "@apollo/client";
import { defaultPrestador, defaultRenditionByDocTrib, defaultRowsPerPage, stateTrackingRendition } from "../../utils/constDefault";
import { getFormattedDate } from "../../utils/getFormatDate";
import { getRenditionByTributaryDocument } from '../../services/getRenditionByTributaryDocument';
import { CustomTextField } from "../../theme/ui/textField";
import { CustomInput } from '../../components/UI/Input/input';
import { CustomSelect } from '../../components/ui-components/customSelect/customSelect';
import Breadcrum from "../../components/ui-components/breadcrum/customBreadcrum";
import { boxCardNP, boxPagesContainer, grayBox } from "../../components/ui-components/box/Boxs";
import { altTitle, blackBold3, grayBold, grayText, greenText700, inputText } from "../../components/ui-components/Typography/typography";
import { SelectPrestador } from '../../pages/consultaMedica/components/selectPrestador';
import TableConsultMedicalReport from "./components/tableConsultMedicalReport";
import { ReportMedicalRendition } from "./components/reportMedicalRendition";
import whiteSearch from "../../assets/icons/whiteSearch.svg";
import Lupa from "../../assets/icons/lupaInput.svg";

interface Rendition {
    holding: string;
    stateRendition: string;
    dateInit: Date | null;
    dateEnd: Date | null;
    folioRendition: string;
    folioBono: string;
    folioTributary: string;
    numRendition?: number;
}

interface DateDisabled {
    initDisabled: boolean;
    endDisabled: boolean
}

function ConsultMedicalReport() {
    const [state, setState] = useState<Rendition>(defaultRenditionByDocTrib);
    const { holding, stateRendition, dateInit, dateEnd, folioBono, folioRendition, folioTributary } = state;
    const [numRendition, setNumRendition] = useState(0);
    const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
    const [btnReportDisabled, setBtnReportDisabled] = useState<boolean>(true)
    const [{ initDisabled, endDisabled }, setDateDisabled] = useState<DateDisabled>({ initDisabled: true, endDisabled: true })
    const [prestadores, setPrestadores] = useState<any>(defaultPrestador);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [searchTextbox, setSearchTextbox] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [debouncedQuery, setDebouncedQuery] = useState("");

    const [renditions, setRenditions] = useState([])
    const [getRenditions] = useLazyQuery(getRenditionByTributaryDocument);


    const [loading, setLoading] = useState(false);
    const getMaxDate = () => new Date();
    const [error, setError] = useState<boolean>(false);

    const handleSelectChange = (value: any, key: any) => {
        setState({ ...state, [key]: key === "dateInit" && value === "" ? null : value })
        cleanGrid();
    };

    /**limpiar */
    const cleanGrid = () => {
        if (renditions?.length > 0 && error) {
            setError(false)
            setTotalRecords(0)
            setPage(0);
            setOrderBy('id');
            setOrder('asc');
            setRenditions([])
            setBtnReportDisabled(true)
            setNumRendition(0);
        }
    }

    const handleSearchText = (event: { target: { value: any; }; }) => {
        setSearchTextbox(event.target.value)
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedQuery(searchTextbox);
        }, 1000);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchTextbox]);

    useEffect(() => {
        if ((holding !== "0") || (folioRendition !== "" && folioRendition !== "0") || (folioTributary !== "" && folioTributary !== "0") || (folioBono !== "" && folioBono !== "0")
            || (((stateRendition !== "0") && (dateInit) && (dateEnd)) || ((stateRendition !== "0") && (!dateInit) && (!dateEnd)))) {
            handleGetRenditions(0, rowsPerPage, orderBy, order, searchTextbox)
        }
        //eslint-disable-next-line
    }, [debouncedQuery]);

    const handleGetRenditions = async (pageInit: number, perPage: number, orderBy: string, typeOrder: string, searchT: string) => {
        setLoading(true)
        setRenditions([]);
        return new Promise((resolve, reject) => {
            getRenditions({
                variables: {
                    renditionFilters: {
                        renditionFolio: folioRendition === "" || folioRendition === "0" ? null : Number(folioRendition),
                        rutBilling: holding === "" || holding === "0" || holding === "todos" ? null : holding,
                        renditionStatus: stateRendition === "" || stateRendition === "0" ? null : stateRendition,
                        startDate: dateInit ? getFormattedDate(dateInit) : null,
                        endDate: dateEnd ? getFormattedDate(dateEnd) : null,
                        bonoFolio: folioBono === "" || folioBono === "0" ? null : Number(folioBono),
                        tributaryFolio: folioTributary === "" || folioTributary === "0" ? null : String(folioTributary),
                        searchText: (searchT) ? searchT : null
                    },
                    page: pageInit + 1,
                    perPage: perPage,
                    orderBy: `${orderBy} ${typeOrder}`
                },
                onCompleted: (data) => {
                    setError(true)
                    if (data) {
                        setTotalRecords(data.renditionsByFiltersCCP.totalRecords);
                        setBtnReportDisabled((data.renditionsByFiltersCCP.totalRecords > 0) ? false : true);
                        setNumRendition(data.renditionsByFiltersCCP.countRenditions);
                        setRenditions(data.renditionsByFiltersCCP.data);
                    }
                    setLoading(false)
                    resolve(data);
                },
                onError: (error) => {
                    setLoading(false)
                    reject(error);
                }
            });
        });
    }

    useEffect(() => {
        const newObject = {
            id: '0',
            nombre: 'TODOS',
            value: '0'
        };
        const hasObjectWithIdZero = stateTrackingRendition.some(obj => obj.id === '0');
        if (!hasObjectWithIdZero) {
            stateTrackingRendition.unshift(newObject);
        }
    }, []);

    useEffect(() => {
        setState({ ...state, dateInit: null, dateEnd: null });
        if (stateRendition !== "0") {
            setDateDisabled({ initDisabled: false, endDisabled: true });
            setBtnDisabled(false);
        } else {
            setDateDisabled({ initDisabled: true, endDisabled: true });
            setBtnDisabled(true);
        }
        //eslint-disable-next-line
    }, [stateRendition])

    /** VALIDAR FORM */
    useEffect(() => {
        let validSection1 = (holding !== "0" && holding !== "todos") ? true : false;
        let emptySection1 = (holding === '0' || holding === 'todos') ? true : false;

        let validSection2 = ((folioRendition !== "" && folioRendition !== "0")
            || (folioTributary !== "" && folioTributary !== "0")
            || (folioBono !== "" && folioBono !== "0")) ? true : false;
        let emptySection2 = (folioRendition === "" && folioTributary === "" && folioBono === "") ? true : false;

        let validSection3 = false;
        if (stateRendition !== "0" && !dateInit) {
            validSection3 = true;
        } else if (stateRendition !== "0" && dateEnd) {
            validSection3 = true;
        } else {
            validSection3 = false;
        }

        let emptySection3 = ((stateRendition === "0") && (!dateInit || !dateEnd)) ? true : false;

        /**CASO 1: Sólo una sección seleccionada && validEmpty*/
        if ((validSection1 && emptySection2 && emptySection3)
            || (validSection2 && emptySection1 && emptySection3)
            || (validSection3 && emptySection1 && emptySection2)
        ) {
            setBtnDisabled(false)
        }
        /**CASO 2: Dos secciones seleccionadas */
        else if ((validSection1 && validSection2 && emptySection3)
            || (validSection1 && validSection3 && emptySection2)
            || (validSection2 && validSection3 && emptySection1)
        ) {
            setBtnDisabled(false)
        }
        /**CASO 3: Tres secciones seleccionadas */
        else if (validSection1 && validSection2 && validSection3) {
            setBtnDisabled(false)
        }
        else {
            setBtnDisabled(true)
        }
    }, [holding, stateRendition, dateInit, dateEnd, folioBono, folioRendition, folioTributary])

    useEffect(() => {
        handleSelectChange(null, "dateEnd");
        if (dateInit !== null) {
            setDateDisabled({ initDisabled: false, endDisabled: false })
        }
        //eslint-disable-next-line
    }, [dateInit])

    useEffect(() => {
        setDateDisabled({ initDisabled: true, endDisabled: true })
    }, [])

    return (
        <Box pt="30px" sx={boxPagesContainer} mb="5% !important" role="body">
            <Typography width="86%" m="auto" variant="h4" pb="14px" pt="24px">
                Buscar rendiciones médicas
            </Typography>
            <Box width="86%" m="auto">
                <Breadcrum back1="Inicio"
                    link1="/"
                    now="Buscar rendiciones médicas"
                />
            </Box>
            <Box sx={boxCardNP} mt="10px !important">
                {
                    <>
                        <Grid container p="37px 24px">
                            <Grid item xs={6} pr="16px" pb="14px">
                                <Typography sx={altTitle}>Filtros de búsqueda</Typography>
                                <Typography sx={grayText} pt="9px">Ingrese y seleccione los criterios de búsqueda que desea consultar</Typography>
                                <Typography sx={inputText} pt="15px" pb="6px" >
                                    RUT Facturador solicitante
                                </Typography>
                                <SelectPrestador holding={holding}
                                    handleSelectChange={(e: any) => handleSelectChange(e.target.value, 'holding')}
                                    prestadores={prestadores} setPrestadores={setPrestadores} includeAll={true} />
                            </Grid>
                            <Grid item xs={6} pb="14px">
                                <Box sx={grayBox}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography sx={grayText}>Puedes Ingresar un folio para especificar el criterio de búsqueda.</Typography>
                                        </Grid>
                                        <Grid container spacing={2} pt={1} alignItems="flex-end">
                                            <Grid item xs={4} >
                                                <Typography sx={inputText} pt="12px" pb="6px" >
                                                    Folio rendición
                                                </Typography>
                                                <CustomInput sx={{ backgroundColor: '#fff' }}
                                                    fullWidth
                                                    value={folioRendition}
                                                    placeholder="Ingrese folio"
                                                    onChange={(e: any) => handleSelectChange(e.target.value, "folioRendition")}
                                                    id="folioRendition"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {folioRendition && (
                                                                    <IconButton onClick={() => handleSelectChange('', "folioRendition")} edge="end">
                                                                        X
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} >
                                                <Typography sx={inputText} pt="12px" pb="6px" >
                                                    Folio bono
                                                </Typography>
                                                <CustomInput sx={{ backgroundColor: '#fff' }}
                                                    fullWidth
                                                    placeholder="Ingrese folio"
                                                    value={folioBono}
                                                    onChange={(e: any) => handleSelectChange(e.target.value, "folioBono")}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {folioBono && (
                                                                    <IconButton onClick={() => handleSelectChange("", "folioBono")} edge="end">
                                                                        X
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}  >
                                                <Typography sx={inputText} pt="12px" pb="6px" >
                                                    Folio documento de cobro
                                                </Typography>
                                                <CustomInput sx={{ backgroundColor: '#fff' }}
                                                    fullWidth
                                                    placeholder="Ingrese folio"
                                                    value={folioTributary}
                                                    onChange={(e: any) => handleSelectChange(e.target.value, "folioTributary")}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {folioTributary && (
                                                                    <IconButton onClick={() => handleSelectChange("", "folioTributary")} edge="end">
                                                                        X
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                </Box>
                            </Grid>
                            <Grid item xs={2.4} pr="16px">
                                <Typography sx={inputText} pt="12px" pb="6px" >
                                    Estado rendición
                                </Typography>
                                <CustomSelect
                                    disabled={false}
                                    value={stateRendition}
                                    data={stateTrackingRendition}
                                    titulo={"Seleccione"}
                                    name="stateRendition"
                                    onChange={(e: any) => handleSelectChange(e.target.value, 'stateRendition')}
                                />
                            </Grid>
                            <Grid item xs={2.4} pr="16px">
                                <Typography sx={inputText} pt="12px" pb="6px" >
                                    Fecha Estado desde
                                </Typography>
                                <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
                                    <DatePicker
                                        value={dateInit}
                                        disabled={initDisabled}
                                        maxDate={getMaxDate()}
                                        onChange={(v: any) => handleSelectChange(v, 'dateInit')}
                                        PaperProps={{
                                            className: "paper-calendar",
                                        }}
                                        renderInput={(params: any) => (
                                            <CustomTextField
                                                {...params}
                                                className="full-width"
                                                disabled={initDisabled}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Ingrese fecha",
                                                    readOnly: false,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2.4} pr="16px">
                                <Typography sx={inputText} pt="12px" pb="6px" >
                                    Fecha Estado hasta
                                </Typography>
                                <LocalizationProvider dateAdapter={DateAdapter} locale={es}>
                                    <DatePicker
                                        value={dateEnd}
                                        minDate={dateInit}
                                        maxDate={getMaxDate()}
                                        disabled={endDisabled}
                                        onChange={(v: any) => handleSelectChange(v, 'dateEnd')}
                                        PaperProps={{
                                            className: "paper-calendar",
                                        }}
                                        renderInput={(params: any) => (
                                            <CustomTextField
                                                {...params}
                                                className="full-width"
                                                disabled={endDisabled}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Ingrese fecha",
                                                    readOnly: false,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2.4} pt="38px">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    startIcon={<img src={whiteSearch} alt="buscar" />}
                                    disabled={btnDisabled}
                                    onClick={() => handleGetRenditions(page, rowsPerPage, orderBy, order, searchTextbox)}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container pl="29px" pb="28px">
                            <Grid item xs={12} pt="15px">
                                <Typography sx={blackBold3}>Resultado de búsqueda</Typography>
                                <Typography sx={grayText} pt="9px">Seleccione criterios de búsqueda en la tabla para resultados más específicos.</Typography>
                            </Grid>
                            <Grid item xs={6} pt="15px" pr="16px">
                                <CustomInput
                                    onChange={handleSearchText}
                                    value={searchTextbox}
                                    placeholder={`Selecciona en la cabecera de la tabla el criterio por el que deseas filtrar`}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (<IconButton><img src={Lupa} alt="buscar" /></IconButton>),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} pt="15px">
                                <Stack sx={grayBox} direction={"row"} alignItems={"center"} display={"flex"} justifyContent={"center"}>
                                    <Typography sx={greenText700} pr="10px">RESULTADOS</Typography>
                                    <Divider sx={{ border: "1px solid #D2D6D9", height: "24px" }} />
                                    <Typography sx={grayBold} pl="10px" pr="4px">Rendiciones: </Typography>
                                    <Typography sx={greenText700}> {numRendition}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={3} pt="15px" textAlign={"end"} pr="24px">
                                {<ReportMedicalRendition disabled={btnReportDisabled} holding={holding} stateRendition={stateRendition} dateInit={dateInit} dateEnd={dateEnd} folioBono={folioBono} folioRendition={folioRendition} folioTributary={folioTributary} numRendition={numRendition} searchT={searchTextbox} orderBy={orderBy} typeOrder={order} />}
                            </Grid>
                        </Grid>
                        {<TableConsultMedicalReport datos={renditions} error={error} totalRecords={totalRecords} page={page} setPage={setPage}
                            rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} handleGetRenditions={handleGetRenditions} setSearchTextbox={setSearchTextbox}
                            searchTextbox={searchTextbox} orderBy={orderBy} setOrderBy={setOrderBy} order={order} setOrder={setOrder}
                            loading={loading} />}
                    </>
                }
            </Box>
        </Box>
    );
}

export default ConsultMedicalReport;
