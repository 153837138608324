import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Check from "../../../assets/icons/check.svg";
import Cancel from '../../../assets/icons/cancel.svg'

function Toast({ openAlert, setOpenAlert, contentAlert }: any) {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
  });

  return (
    <Snackbar
        style={contentAlert.styleAlert}
        open={openAlert}
        anchorOrigin={{
          vertical: contentAlert.typeAlert || 'bottom',
          horizontal: 'center'
        }}
        autoHideDuration={6000}
        onClose={() => setOpenAlert(false)}
    >
      {contentAlert.isSuccess === true ? (
        <Alert
          onClose={() => setOpenAlert(false)}
          severity="success"
          sx={{ width: "100%" }}
          icon={<img src={Check} alt="" />}
        >
          {contentAlert.content}
        </Alert>
      ) : (
        <Alert
          onClose={() => setOpenAlert(false)}
          severity="error"
          sx={{ width: "100%" }}
          icon={<img src={Cancel} alt=""/>}
        >
          {contentAlert.content}
        </Alert>
      )}
    </Snackbar>
  );
}

export default Toast;
