import { gql } from "@apollo/client"

export const downloadFileCorrectEntries = gql`
query downloadFileCorrectEntries($idNumOperacion : Int) {
    downloadFileCorrectEntries(idNumOperacion: $idNumOperacion) {
        estado,
        mensaje,
        data {
            idTipoProceso
            tipoProceso
            idDocumentoCentralizacion
            idItemCentralizador
            itemProceso
            claveControlSAP
            fechaRegistro
            fechaContable
            sociedadFISAP
            cuentaContableSAP
            codigoProveedorSAP
            importe
            codigoClienteSAP
            claveBancoSAP
            cuentaBancariaSAP
        }
    }
}`;