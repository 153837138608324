import { useEffect, useState } from "react";
import { queryGetPresignedUrl } from "../../services/getPresignedUrl";
import { useLazyQuery } from "@apollo/client";

export const useBulkBonusUpload = () => {
  const [filename, setFilename] = useState<string>("");
  const [presignedURL, setPresignedURL] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [getData, { loading }] = useLazyQuery(queryGetPresignedUrl, {
    variables: {
      filename
    }
  });

  useEffect(() => {
    if (filename !== "") {
      console.log("generando URL prefirmada para ", filename);
      handleGetURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filename]);

  const handleGetURL = async () => {
    try {
      const { data: { getPreSignedURL } } = await getData();
      if (getPreSignedURL.estado === 200) {
        setPresignedURL(getPreSignedURL.mensaje);
      }
    } catch (error: any) {
      setError(error.message)
    }
  }

  const handleFileChange = (filePath: string, folder: string) => {
    const chunks = filePath.split(/\/|\\/);
    setFilename(`${folder}/${chunks[chunks.length - 1]}`);
  };

  return {
    error,
    filename,
    loading,
    presignedURL,
    handleFileChange,
    handleGetURL,
  };
};
