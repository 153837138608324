import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    Button,
    TableSortLabel,
    Checkbox
} from "@mui/material";
import { useEffect, useState } from "react";
import PencilIcon from "../../assets/icons/pencilIcon.svg";
import DocumentIcon from "../../assets/icons/documentIcon.svg";
import { NoResult } from "../ui-components/Tables/noResult";
import { ModalEditarRendicion } from "./modalEditar";
import ButtonSelect from '../../components/medicalRenditions/buttonSelect';
import { StyledTooltip } from "../ui-components/Tooltip/customTooltip";
import { StatesRendition } from "./statesRendition";
import { getFormattedDate } from "../../utils/getFormatDate";
import { formatNumber } from "../../utils/formatNumber";
import { DetailsTooltip } from "./detailsTooltip";
import { headCells, perPageGlobal } from "../../utils/const";
import { Loading } from "../isLoading/Loading";
import { formatRut } from "../../utils/formatRut";
import { DropdownIndicator } from "../ui-components/Tables/dropDownIndicator";
import { ModalDocumentos } from "../ui-components/Modals/modalDocumentos";
import { useLazyQuery } from "@apollo/client";
import { GetTributaryDocsByRenditionId } from "../../services/getTributaryDocsByRenditionId";
import { normalHead, stickyCol, stickyHead } from "../UI/Table/table";
import Toast from "../ui-components/Toast/Toast";

const TableMedicalSurrender = ({handleRefreshRenditions, medicalRenditions, totalRecords, 
    loading, orderBy, setOrderBy, order, setOrder, page, setPage, rowsPerPage, setRowsPerPage, 
    searchTextbox, setSearchTextbox, setMedicalRenditions, cleanChecks, setCleanChecks}: any) => {
    
    const [openToastSolicitud, setOpenToastSolicitud] = useState<boolean>(false)
    const [toastSolicitud, setToastSolicitud] = useState({content: "", isSuccess: false})
    
    const [modalState, setModalState] = useState(false);
    const [rendition, setRendition] = useState({
        dateProbablePayment: null,
        dateState: null,
        id: 0,
        idState: 0,
        note: null,
        renditionDate: null,
        rutProvider: "",
        state: null,
        SAPPaymentReferenceKey: "",
        SAPProposalFolio: "",
        paymentDate: null
    });
    const [modalDocument, setModalDocument] = useState(false)
    const [loader, setLoading] = useState(false);

    const [checkedRendition, setCheckedRendition] = useState<any>({});
    const [selectedRends, setSelectedRends] = useState<any[]>([]);
    const [globalIdState, setGlobalIdState] = useState(null);
    const [titleEdit, setTitleEdit] = useState('');
    const [subTitleEdit, setSubTitleEdit] = useState('');
    
    const handleChangePage = (_event: any, newPage: any) => {
        setPage(newPage);
        handleRefreshRenditions(newPage, rowsPerPage, orderBy, order, searchTextbox)
    };

    const handleChangeCheckBox = ({ value, name, checked }: { value: string; name: string; checked: boolean }) => {
        setCheckedRendition((rend: any) => ({
            ...rend,
            [name]: checked,
        }));
        const selectedRend = medicalRenditions.find((r: any) => String(r.id) === name);

        if (checked) {// esta chequeado, se podría pushear
            if (selectedRends.length === 0) {
                setGlobalIdState(selectedRend.idState);
                setSelectedRends([selectedRend]);
            }
            else if (selectedRend.idState === globalIdState) {
                setSelectedRends(rend => [...rend, selectedRend]);
            }
            else {//no puede insertar estados distintos
                if (selectedRends.length === 0) {
                    setGlobalIdState(null);//reset global idState
                }
            }
        }
        else { // deschequeado, por lo tanto, quitar
            setSelectedRends(rend => rend.filter((r: any) => String(r.id) !== name));
        }
        
    };

    /** reset global idState */
    useEffect(() => {
        if (selectedRends.length === 0) {
            setGlobalIdState(null);
        }
    }, [selectedRends]);

    useEffect(() => {
        if (cleanChecks) {
            setSelectedRends([]);
            setCheckedRendition({});
        }
    }, [cleanChecks]);

    const handleChangeRowsPerPage = (event: any) => {
        const perPage = parseInt(event.target.value, 10);
        setPage(0)
        setRowsPerPage(perPage)
        handleRefreshRenditions(0, perPage, orderBy, order, searchTextbox)
    };

    const handleSortClick = (columnId: any) => {        
        if (orderBy === columnId) {
          let orderMod = order === 'asc' ? 'desc' : 'asc';
          setOrder(orderMod);
          handleRefreshRenditions(page, rowsPerPage, columnId, orderMod, searchTextbox)
        } else {
          setSearchTextbox("")
          setOrderBy(columnId);
          setOrder('asc');
          handleRefreshRenditions(page, rowsPerPage, columnId, 'asc', "")
        }
        
    };
    const handleState = (renditionData: any) => {
        setRendition(renditionData)
        setTitleEdit('Editar');
        setSubTitleEdit('Modifica los campos necesarios para actualizar la información.');
        setModalState(true)
    }
   
    const handleMultiState = (selectedRends: any[]) => {
        setRendition({
            dateProbablePayment: null,
            dateState: null,
            id: 0,
            idState: selectedRends[0].idState,
            note: null,
            renditionDate: null,
            rutProvider: "",
            state: null,
            SAPPaymentReferenceKey: "",
            SAPProposalFolio: "",
            paymentDate: null
        });
        setModalState(true);
        setTitleEdit('Cambiar estado');
        setSubTitleEdit('Modifica los estados de manera múltiple.');
    }

    
    const handleDocuments = (renditionId: any)=>{
        obtainDocumentList(renditionId);     
    }

    const [getDocumentList] = useLazyQuery(GetTributaryDocsByRenditionId);
    const [documentData, setDocumentData] = useState([]);
    const obtainDocumentList = (renditionId: any) => {
        setLoading(true)
        return new Promise((resolve, reject) => {
            getDocumentList({
                variables: {
                    idRendition: renditionId
                },
                onCompleted: (data) => {
                    if (data) {
                        setModalDocument(true);
                        setDocumentData(data.tributaryDocsByRenditionId.data);
                    }
                    setLoading(false)
                    resolve(data);
                },
                onError: (error) => {
                    setLoading(false)
                    reject(error);
                }
            });
        });
    }

    const getDirection = (columnId: string): "asc" | "desc" | undefined => {
        if (orderBy === columnId) {
          return order as "asc" | "desc"; 
        }
        return 'asc'; 
    };

    return (
        <> {openToastSolicitud ? (
            <Toast
                openAlert={openToastSolicitud}
                setOpenAlert={setOpenToastSolicitud}
                contentAlert={toastSolicitud}
                onClose={() => setOpenToastSolicitud(false)}
            />
        ) : null}
            <ModalEditarRendicion
                {...{
                    onOpen: modalState,
                    setOpen: setModalState,
                    data: rendition,
                    setData: setRendition,
                    handleRefreshRenditions,
                    medicalRenditions,
                    page,
                    rowsPerPage,
                    orderBy,
                    order,
                    searchTextbox,
                    titleEdit,
                    subTitleEdit,
                    selectedRends,
                    setSelectedRends,
                    setCheckedRendition,
                    openToastSolicitud,
                    setOpenToastSolicitud,
                    toastSolicitud,
                    setToastSolicitud
                }}
            />

            <ModalDocumentos
                {...{
                    onOpen: modalDocument,
                    setOpen: setModalDocument,
                    data: documentData,
                    page,
                    rowsPerPage
                }}
            />

             {
                loading &&
                <Loading title={"Cargando rendiciones médicas..."} />
            }
            {
                medicalRenditions ?
                <Paper sx={{ padding: "0 !important" }}>
                    <TableContainer component={Paper} sx={{ padding: "0 !important" }}>
                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                            <TableHead>    
                                <TableRow>
                                        <TableCell padding="checkbox" className="tCell-p headerSort-unlined">
                                            <Checkbox
                                                color="primary"
                                                inputProps={{ "aria-label": "select all renditions" }}
                                            />
                                        </TableCell>
                                {headCells.map((headCell) => (
                                                                             
                                        <TableCell
                                            key={headCell.id}
                                            align={'center'}
                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                            className={ orderBy === headCell.id ? "escritorioTrabajo activeSort" : "escritorioTrabajo headerSort"}
                                            sx={(headCell.id === '8' || headCell.id === '9' )? stickyHead : normalHead}
                                        >
                                            {headCell.sorted ?
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={getDirection(headCell.id)}
                                                    onClick={() => handleSortClick(headCell.id)}
                                                    IconComponent={() => <DropdownIndicator active={orderBy === headCell.id} order={orderBy === headCell.id ? order : 'desc'} />}>
                                                    {headCell.label}
                                                </TableSortLabel>
                                                :
                                                <span>{ headCell.label }</span>
                                            }
                                        </TableCell>

                                    ))}    
                                </TableRow>                               
                            </TableHead>
                            <TableBody>
                           
                            {
                                medicalRenditions.map((rendition: any) => (
                                <TableRow key={rendition.id}>
                                        <TableCell
                                            padding="checkbox"
                                            className="tCell-p headerSort-unlined">
                                            <Checkbox
                                                data-testid='checkbox-1'
                                                name={String(rendition.id)}
                                                value={rendition}
                                                color="primary"
                                                checked={!!checkedRendition[rendition.id]}
                                                disabled={(globalIdState !== null && rendition.idState !== globalIdState)}
                                                onChange={(event) => handleChangeCheckBox({
                                                    value: event.target.value,
                                                    name: event.target.name,
                                                    checked: event.target.checked,
                                                })}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </TableCell>
                                    <StyledTooltip
                                        arrow
                                        placement="right"
                                        title={
                                            <DetailsTooltip provider={rendition.nameProvider} totalDocTrib={rendition.totalDocsTrib} totalVoucher={rendition.totalVouchers} canal={"WEB"} />
                                        }
                                    >
                                        <TableCell component="th" scope="row">
                                            { rendition.id }
                                        </TableCell>
                                    </StyledTooltip>

                                    <TableCell>
                                        {getFormattedDate(rendition.renditionDate)}
                                    </TableCell>
                                    <TableCell>
                                        {formatRut(rendition.rutProvider)}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(rendition.totalAmount)}
                                    </TableCell>
                                    <TableCell>
                                        {formatNumber(rendition.totalToPay)}
                                    </TableCell>
                                    <TableCell>
                                        {getFormattedDate(rendition.dateProbablePayment)}
                                    </TableCell>
                                    <TableCell>
                                        <StatesRendition state={rendition.idState} />
                                    </TableCell>
                                    <TableCell sx={stickyCol}>
                                        {getFormattedDate(rendition.dateState)}
                                    </TableCell>
                                    <TableCell align="center" sx={stickyCol}>
                                        <Button
                                            variant="outlined"
                                            className="doc-button"
                                            disableRipple
                                            sx={{ p: "4px 22px !important" }}
                                            onClick={() => handleDocuments(rendition.id)}>
                                            <img src={DocumentIcon} alt="document icon" />
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button      
                                            data-testid='pencil-button'                             
                                            variant="outlined"
                                            className="doc-button"
                                            disableRipple
                                            disabled={(globalIdState!==null)}
                                            sx={{ p: "4px 22px !important" }}
                                            onClick={() => handleState(rendition)}
                                        >
                                            <img src={PencilIcon} alt="document icon" />
                                        </Button>
                                    </TableCell>                                    
                                </TableRow>
                            ))}

                            
                            </TableBody>
                        </Table>
                        { ((selectedRends?.length || 0) > 0 && !modalState) && 
                        <ButtonSelect checkCount={selectedRends?.length || 0} setOpen={()=>handleMultiState(selectedRends)}/>}
                    </TableContainer>

                    <TablePagination
                        data-testid='pagination'
                        rowsPerPageOptions={perPageGlobal}
                        component="div"
                        count={totalRecords}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ mt: "14px", mb: "14px" }}
                        labelRowsPerPage={ `Mostrar por página`}
                        labelDisplayedRows={(e) => {
                            return (
                                "" +
                                (page + 1) +
                                " de " +
                                Math.ceil(totalRecords === 0 ? 0 : totalRecords / rowsPerPage)
                            );
                        }}
                    />
                </Paper >
                : 
                <NoResult
                    newFound
                    titulo={"No se encontró información de rendiciones"}
                    subtitulo={"Inténtelo nuevamente usando otra información de filtro"} />
            }
            {
                loader &&
                <Loading title={"Cargando información de documentos..."} />
            }
        </>
    )
}

export default TableMedicalSurrender;