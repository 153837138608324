export const defaultRowsPerPage = 10;

export const IVA = 19;

export const perPageGlobal = [10, 20, 30, 50, 100];

export const headCells = [
    {
      id: '1',
      columnName: "id",
      numeric: false,
      disablePadding: true,
      label: 'Nº Rendición',
      sorted: true,
    },{
        id: '2',
        columnName: "renditionDate",
        numeric: false,
        disablePadding: true,
        label: 'Fecha de recepción',
        sorted: true,
    },{
        id: '3',
        columnName: "rutProvider",
        numeric: false,
        disablePadding: true,
        label: 'RUT prestador',
        sorted: true,
    },{
        id: '4',
        columnName: "totalAmount",
        numeric: false,
        disablePadding: true,
        label: 'Total facturado',
        sorted: true,
    },{
        id: '5',
        columnName: "totalToPay",
        numeric: false,
        disablePadding: true,
        label: 'Total a pagar',
        sorted: true,
    },{
        id: '6',
        columnName: "dateProbablePayment",
        numeric: false,
        disablePadding: true,
        label: 'F. probable de pago',
        sorted: true,
    },{
        id: '7',
        columnName: "idState",
        numeric: false,
        disablePadding: true,
        label: 'Estado',
        sorted: true,
    },{
        id: '8',
        columnName: "dateState",
        numeric: false,
        disablePadding: true,
        label: 'Fecha Estado',
        sorted: true,
    },{
        id: '9',
        columnName: "viewDocument",
        numeric: false,
        disablePadding: true,
        label: 'Ver Documento',
        sorted: false,
    },{
        id: '10',
        columnName: "edit",
        numeric: false,
        disablePadding: true,
        label: 'Editar Estado/Nota',
        sorted: false,
    },
];

/** Encabezados de reporte de gestión */
export const headExcelReport: never | string[] = [
    'ID Rendicion',
    'ID Estado',
    'Desc Estado',
    'Fecha Estado',
    'Nota Resolutiva',
    'Fecha Recepcion',
    'Fecha Probable Pago',
    'Fecha Real Pago',
    'Rut Holding',
    'Razon Social Holding',
    'Rut Facturador',
    'Razon Social Facturador',
    'Monto Total Facturado',
    'Monto Total Rendido',
    'Diferencia Total AjusteSencillo',
    'Monto Total Pago',
    'Total Bonos Rendidos',
    'Clave Referencia Pago SAP',
    'Folio Propuesta SAP', 
    'ID Doc Cobro Tributario', 
    'ID Tipo Doc Cobro', 
    'Desc Tipo Doc Cobro',
    'Folio Doc Tributario', 
    'Fecha Doc Tributario', 
    'Monto Neto', 
    'Monto Exento', 
    'Monto Impuesto', 
    'Monto Total', 
    'Canal Bono', 
    'Ley Urgencia',
    'Preventivo',
    'Folio Cuenta Médica',
    'Tipo Cobertura',
    'Tipo Atencion',
    'Total Monto Prestacion',
    'Total Monto Bonificado',
    'Total Monto Copago',
    'Total Monto Rendido',
    'Total Monto Pagar'
]

export const headExcelCorrectEntries: never | string[] = [
    "ID Proceso",
    "Descripción Proceso", 
    "Num. Operación", 
    "Item de Proceso", 
    "Descripción Item Proceso",
    "Num. Asiento", 
    "Fecha Registro en SAP", 
    "Fecha Contable", 
    "Sociedad FI", 
    "Num Cuenta Contable", 
    "Codigo Proveedor", 
    "Importe Documento",
    "Codigo Cliente",
    "Clave Banco",
    "Cuenta Bancaria",
]

export const headCellsPaymentRequest = [
    {
      id: '1',
      columnName: "idNumOperacion",
      numeric: false,
      disablePadding: true,
      label: 'Nº Operación',
      sorted: true,
    },{
        id: '2',
        columnName: "fechaSoli",
        numeric: false,
        disablePadding: true,
        label: 'Fecha Sol. SAP',
        sorted: true,
    },{
        id: '3',
        columnName: "horaSoli",
        numeric: false,
        disablePadding: true,
        label: 'Hora Sol.',
        sorted: true,
    },{
        id: '4',
        columnName: "itemOperacion",
        numeric: false,
        disablePadding: true,
        label: 'Item operación',
        sorted: true,
    },{
        id: '5',
        columnName: "estadoSAP",
        numeric: false,
        disablePadding: true,
        label: 'Estado',
        sorted: true,
    },{
        id: '6',
        columnName: "fechaEstadoSap",
        numeric: false,
        disablePadding: true,
        label: 'Fecha estado',
        sorted: true,
    },{
        id: '7',
        columnName: "totalAsientosOKSAP",
        numeric: false,
        disablePadding: true,
        label: 'Total Asientos Proc.',
        sorted: true,
    },{
        id: '8',
        columnName: "importeDocSAP",
        numeric: false,
        disablePadding: true,
        label: 'Monto Total Proc.',
        sorted: true,
    },{
        id: '9',
        columnName: "viewDocument",
        numeric: false,
        disablePadding: true,
        label: 'Acciones',
        sorted: false,
    }
];

export const renditionState = [
    {
        id: 5,
        nombre: 'Solicitada',
        value: '5'
    },
    {
        id: 1,
        nombre: 'En propuesta',
        value: '1'
    },
    {
        id: 3,
        nombre: 'Rechazada',
        value: '3'
    },
    {
        id: 2,
        nombre: 'Pagada',
        value: '2'
    },
    {
        id: 6,
        nombre: 'Revisada',
        value: '6'
    }
]

export const renditionProcess = [
    { id: 2, nombre: 'Beneficios', value: 2 },
    { id: 1, nombre: 'Operaciones', value: 1 },
    { id: 4, nombre: 'Personas', value: 4 },
    { id: 5, nombre: 'Gerencia General', value: 5 },
];

export const renditionProcessState = [
    { id: 9, nombre: 'Procesada en SAP', value: 9 },
    { id: 13, nombre: 'Revisada', value: 13 },
    { id: 12, nombre: 'En Propuesta', value: 12 }
];