import { gql } from "@apollo/client"

export const getMedicalRenditions = gql`
query getMedicalRenditions($page: Int, $perPage: Int, $orderBy: String, $columnId: String, $searchText: String) {
    getMedicalRenditions(page: $page, perPage: $perPage, orderBy: $orderBy, columnId: $columnId, searchText: $searchText) {
        estado,
        mensaje,
        totalRecords,
        data {
            id
            canal
            totalAmount
            totalDocsTrib
            dateState
            totalVouchers
            note
            state
            dateProbablePayment        
            renditionDate
            totalToPay
            rutProvider
            nameProvider
            idState
            paymentDate
            SAPPaymentReferenceKey
            SAPProposalFolio
        }
    }        
}`;