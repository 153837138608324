import { parse, format, isBefore, differenceInMonths } from "date-fns";
import moment from 'moment';
export function getFormattedDate(date: any) {
    try {
        if (date instanceof Date) {
            return format(date, 'dd-MM-yyyy');
          }
        
          const parts = date.split(/[-T:]/);
        
          const newDate = new Date(
            Number(parts[0]),    
            Number(parts[1]) - 1, 
            Number(parts[2]),    
          );
        
          return format(newDate, 'dd-MM-yyyy');
    } catch (error) {
        return ""
    }    
}

export function getFormat(date: any) {
  try {
      if (date instanceof Date) {
          return format(date, 'yyyy-MM-dd');
        }
      
        const parts = date.split(/[-T:]/);
      
        const newDate = new Date(
          Number(parts[0]),    
          Number(parts[1]) - 1, 
          Number(parts[2]),
        );
      
        return format(newDate, 'yyyy-MM-dd');
  } catch (error) {
      return ""
  }    
}

export function getFormattedddMMyyyy(date: any) {
  try {
      if (date instanceof Date) {
          return format(date, 'ddMMyyyy');
        }
      
        const parts = date.split(/[-T:]/);
      
        const newDate = new Date(
          Number(parts[0]),    
          Number(parts[1]) - 1, 
          Number(parts[2]),
        );
      
        return format(newDate, 'ddMMyyyy');
  } catch (error) {
      return ""
  }    
}
  
export function convertStringDate(date: any) {
    try {
          const parts = date.split(/[-T:]/); 
          const newDate = new Date(
            Number(parts[0]),    
            Number(parts[1]) - 1, 
            Number(parts[2]),    
          );
          const formatDate = new Date(newDate)
          return formatDate;
    } catch (error) {
        return ""
    }    
}

export function validateDateByMonths(startDate: Date, endDate: Date, months: number) {
    if (isBefore(startDate, endDate) && differenceInMonths(endDate, startDate) <= months) {
        return true;
    } else {
        return false;
    }
}
