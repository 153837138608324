import {
    DialogContent,
    Typography,
    Stack
} from "@mui/material";
import { CustomModal } from "../UI/Modal/customModal";
import { typoGraphySubtitle } from "../ui-components/Typography/typography";


export const Loading = ({title}: any) => {
    return (
        <CustomModal
            fullWidth
            maxWidth="sm"
            open={true}
            sx={{
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                }, 

            }}
        >
            <DialogContent sx={{ padding: "48px 24px !important" }}>
                <Stack direction={"column"} alignItems={"center"} display={"flex"} pt="28px" pb="28px" >
                    <div className="blob"></div>
                    <Typography sx={typoGraphySubtitle} data-testid="text-element">{title}</Typography>                    
                </Stack>
            </DialogContent>


        </CustomModal>
    );
};
