const ROLES = [
    {nombre: "ROL_OPERACIONES", rango: 1, id: "11", real_name: "OPERACIONES"},
    {nombre: "ROL_BENEFICIOS", rango: 5, id: "12", real_name: "BENEFICIOS"},
    {nombre: "ROL_TESORERIA", rango: 6, id: "13", real_name: "TESORERIA"},
    {nombre: "ROL_PERSONAS", rango: 4, id: "14", real_name: "PERSONAS"},
    {nombre: "ROL_GERENTE_GENERAL", rango: 2, id: "15", real_name: "GERENCIA GENERAL"},
    {nombre: "ROL_CONTABILIDAD", rango: 3, id: "20", real_name: "CONTABILIDAD"}, // DEFINIR OPCIONES A MOSTRAR
    {nombre: "ROL_UNIDAD_PAGO", rango: 7, id: "21", real_name: "Unidad de Pago"},
]

export const returnRolByJerarquia = (roles: any) => {
    let maxValue = 0;
    let rolMax = "";
    roles.forEach((rol: any) => {
        ROLES.forEach((item: any) => {
            if (item.nombre.toLowerCase() === rol.toLowerCase() && item.rango > maxValue) {
                maxValue = item.rango
                rolMax = item.real_name
            }
        })
    })
    return rolMax
}
export const returnID = (roles: any) => {
    let maxValue = 0;
    let idMax = 0
    roles.forEach((rol: any) => {
        ROLES.forEach((item: any) => {
            if (item.nombre.toLowerCase() === rol.toLowerCase() && item.rango > maxValue) {

                idMax = item.id
            }
        })
    })
    return idMax
}

export const checkDeleteFile = (status: string) => {

    switch (status) {
        case "ARCHIVO CARGADO":
            return false;
        case "ARCH. VAL. CON EXITO":
            return false
        case "ARCH. VAL. CON ERROR":
            return false
        default:
            return true
    }
}

export const checkRechazoRol = (rol: string, rolProceso: string) => {
    let stringRol = rolProceso.split("|")
    return (rol === stringRol[1])
}

