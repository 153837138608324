import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import SearchIcon from "../../assets/icons/searchIcon.svg";
import TableMedicalSurrender from "../../components/medicalRenditions/tableMedicalSurrender";
import { titleBox } from "../../components/ui-components/Typography/typography";
import { boxCardNP } from "../../components/ui-components/box/Boxs";
import Breadcrum from "../../components/ui-components/breadcrum/customBreadcrum";
import { CustomInput } from "../../components/UI/Input/input";
import { getMedicalRenditions } from "../../services/getMedicalRenditions";
import { defaultRowsPerPage, headCells } from "../../utils/const";

export default function DesktopRendition() {
    const [medicalRenditions, setMedicalRenditions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [orderBy, setOrderBy] = useState('6');
    const [order, setOrder] = useState('asc');
    const [searchTextbox, setSearchTextbox] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const [cleanChecks, setCleanChecks] = useState(0);

    const [getRenditions] = useLazyQuery(getMedicalRenditions);

    const handleRefresh = () => {
        handleRefreshRenditions(page, rowsPerPage, orderBy, order, searchTextbox)
        setCleanChecks(prevCount => prevCount + 1);
    }

    const handleRefreshRenditions = (pageInit: number, perPage: number, orderBy: string, typeOrder: string, searchT: string) => {
        setLoading(true)
        let column: any = headCells.find(x => x.id === orderBy)?.columnName;
        return new Promise((resolve, reject) => {
            getRenditions({
                variables: {
                    page: pageInit + 1,
                    perPage: rowsPerPage,
                    orderBy: `${orderBy} ${typeOrder}`,
                    columnId: column,
                    searchText: searchT
                },
                onCompleted: (data) => {
                    if (data) {
                        setMedicalRenditions(data.getMedicalRenditions.data);
                        setTotalRecords(data.getMedicalRenditions.totalRecords)
                    }
                    setLoading(false)
                    resolve(data);
                },
                onError: (error) => {
                    setLoading(false)
                    reject(error);
                }
            });
        });
    };

    useEffect(() => {
        handleRefreshRenditions(page, rowsPerPage, orderBy, order, searchTextbox)
        //eslint-disable-next-line
    }, [])

    const handleSearchText = (event: { target: { value: any; }; }) => {
        setSearchTextbox(event.target.value)
    }

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            setDebouncedQuery(searchTextbox);
        }, 2000);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchTextbox]);

    useEffect(() => {
        handleRefreshRenditions(0, rowsPerPage, orderBy, order, searchTextbox)
        //eslint-disable-next-line
    }, [debouncedQuery]);

    return (
        <>
            <Box pt="30px" mb="5% !important" role="body">
                <Typography width="86%" m="auto" variant="h4" pb="14px" pt="24px">
                    Solicitudes de rendiciones médicas
                </Typography>
                <Box width="86%" m="auto">
                    <Breadcrum
                        back1="Inicio"
                        link1="/"
                        back2="Escritorio de trabajo"
                        link2="/"
                        now="Solicitudes de rendiciones médicas"
                    />
                </Box>

                <Box sx={boxCardNP} mt="10px !important">
                    <Grid container p="24px">
                        <Grid item xs={12} pr="5%" pb="16px">
                            <Typography sx={titleBox}>Detalle de rendiciones médicas</Typography>
                        </Grid>
                        <Grid item xs={11} pr="30px">
                            <CustomInput
                                onChange={handleSearchText}
                                value={searchTextbox}
                                placeholder={`Selecciona en la cabecera de la tabla el criterio por el que deseas filtrar`}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"><img src={SearchIcon} alt="search icon" /></InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} pr="5%" pb="24px">
                            <Button
                                onClick={handleRefresh}
                                variant="contained"
                                sx={{ boxShadow: 'none' }}
                                disableRipple>
                                Refrescar
                            </Button>
                        </Grid>
                    </Grid>
                    <TableMedicalSurrender {...{
                        handleRefreshRenditions, medicalRenditions, totalRecords,
                        loading, orderBy, setOrderBy, order, setOrder, page, setPage, rowsPerPage, setRowsPerPage,
                        searchTextbox, setSearchTextbox, setMedicalRenditions, cleanChecks, setCleanChecks
                    }} />
                </Box>
            </Box>
        </>
    )
}



