import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { CustomModal, CustomModalTitle } from "../UI/Modal/customModal";
import { blackBold, detailText, detailTextB, modalSubtitle, modalTitleAlt, purpleLittle, warningGray, warningPurple, warningPurpleBold } from "../ui-components/Typography/typography";
import { CustomSelect } from "../ui-components/customSelect/customSelect";
import { CustomInput } from "../UI/Input/input";
import { getFormattedDate, convertStringDate } from "../../utils/getFormatDate";
import { renditionState } from "../../utils/const";
import { formatRut } from "../../utils/formatRut";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import es from "date-fns/locale/es";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { CustomTextField } from "../../theme/ui/textField";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { UpdateNote, UpdateNoteMulti } from "../../services/updateNote";
import { useGetUser } from "../../hooks/getUser";
import { Loading } from "../isLoading/Loading";
import moment from "moment";
import _ from "lodash";
import { formatNumber } from "../../utils/formatNumber";
import { WarningModalCustom } from "./modalWarning";
import { modalEditarHandleFormChange } from "./functions/handlers";
const { add } = require('date-fns');

export const ModalEditarRendicion = ({ onOpen, onClose, setOpen, data, setData, handleRefreshRenditions,  
    medicalRenditions,  page, rowsPerPage,  orderBy, order, searchTextbox, titleEdit, subTitleEdit, 
    selectedRends, setSelectedRends, setCheckedRendition, openToastSolicitud, setOpenToastSolicitud,
    toastSolicitud, setToastSolicitud }:  any) => {
    
    const maxLength = 250;
    const minLengthSAP = 3;
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [SAPEnabled, setSAPEnabled] = useState<boolean>(false);
    const [payDateDisabled, setPayDateDisabled] = useState<boolean>(true);
    const [updateNoteRendition, { loading }] = useMutation(UpdateNote);
    const {email} = useGetUser()
    const [updateNoteMulti] = useMutation(UpdateNoteMulti);
    const idsArray = selectedRends?.map((rend: any) => Number(rend.id));
    const suma = _.sumBy(selectedRends, (e: any) => { return e.totalToPay?? 0 });
    const [openWarning, setOpenWarning] = useState(false);
    const [spinner, setSpinner] = useState(false);
   
    const handleClose = () => { 
        setOpen(false)
        cleanStringInputs();
    }

    /** Limpiar */
    const cleanStringInputs =()=>{
        const dataMulti = {
            id: 0,
            paymentDate: null,
            SAPPaymentReferenceKey: "",
            SAPProposalFolio: "",
            note: ""
        };
        setData({data, ...dataMulti});
        setSelectedRends([]);
        setCheckedRendition({});
    }

    const handleFormChange = ({ value, name }: any) => {
        const newData = modalEditarHandleFormChange({ value, name, maxLength, data});
        setData(newData);    
    }

    const handleNoteRendition = () => {
        updateNoteRendition({ variables: { id: data.id, 
            idState: Number(data.idState), 
            note: (data.note) ? data.note : '',
            SAPPaymentReferenceKey: (data.SAPPaymentReferenceKey) ? data.SAPPaymentReferenceKey : '',
            SAPProposalFolio: (data.SAPProposalFolio) ? data.SAPProposalFolio : '',
            paymentDate: (moment(data.paymentDate).startOf('day'))?? null, 
            providerUser: email
        }})
        .then((response:any) => {
            if(response.data.updateNote.estado === 200) {
                handleRefreshRenditions(page, rowsPerPage, orderBy, order, searchTextbox)        
                setOpen(false);
            }
        })
        .catch((error) => {
            setOpenToastSolicitud(true);
            setToastSolicitud({ content: "Ocurrió un error al guardar los cambios. Por favor, inténtalo de nuevo más tarde.", isSuccess: false });
        })
    }

    const handleCloseWarning = () => {
        setOpenWarning(false);
      };

    /** Llamar al servicio updatemulti*/
    const handleNoteMulti = () => {
        setSpinner(true);
        updateNoteMulti({
            variables: {
                id: idsArray,
                idState: Number(data.idState),
                note: (data.note) ? data.note : '',
                SAPPaymentReferenceKey: (data.SAPPaymentReferenceKey) ? data.SAPPaymentReferenceKey : '',
                SAPProposalFolio: (data.SAPProposalFolio) ? data.SAPProposalFolio : '',
                paymentDate: moment(data.paymentDate).startOf('day') ?? null,
                providerUser: email
            }
        })
            .then((response: any) => {
                if (response.data.updateNoteMulti.estado === 200) {
                    handleRefreshRenditions(page, rowsPerPage, orderBy, order, searchTextbox)
                    setSpinner(false);
                    setOpenWarning(false);
                    handleClose();
                    setOpenToastSolicitud(true);
                    setToastSolicitud({ content: "Los cambios han sido guardados exitosamente", isSuccess: true });
                }
            })
            .catch((error) => {
                setSpinner(false);
                setOpenWarning(false);
                handleClose();
                setOpenToastSolicitud(true);
                setToastSolicitud({ content: "Ocurrió un error al guardar los cambios. Por favor, inténtalo de nuevo más tarde.", isSuccess: false });
            })
    }


    /** Estado btn */
    const validFormToSend = (data: any) => {
        let isValid = false;
        if (data.idState === 1) {//En propuesta
            isValid = (data.paymentDate == null
                && (data.SAPPaymentReferenceKey?.length >= minLengthSAP
                && data.SAPProposalFolio?.length >= minLengthSAP))
                setButtonEnabled(isValid)
        }
        else if ((data.idState === 2)) {//pagada
            isValid = (data.paymentDate != null)
            setButtonEnabled(isValid)
        }
        else if ((data.idState === 3)) {//rechazada
            isValid = (data.note?.length >= minLengthSAP)
            setButtonEnabled(isValid)
        }
        else if ((data.idState === 5 || data.idState === 6)) {//solicitada o revisada
            isValid = (data.idState === 5 || data.idState === 6)
            setButtonEnabled(isValid)
        }
        else{
            setButtonEnabled(false)
        }  
    }

    /** Habilitación de campos */
    const handleEnabledButton = (idState: any) =>{//habilitación de campos
        const state = parseInt(idState, 10);
        
        if (state === 1) {//En propuesta
            setSAPEnabled(true)
            setPayDateDisabled(true)//estado inicial
        }
        else if ((state === 2)) {//pagada
            setSAPEnabled(false)//estado inicial
            setPayDateDisabled(false)
        }
        else { // casos 3, 5 y 6
            setSAPEnabled(false)//estado inicial
            setPayDateDisabled(true)//estado inicial
        }
    }

    useEffect(() => {
        handleEnabledButton(data.idState)
        validFormToSend(data);
        //TODO: Validar nota resolutiva x sql inject
    }, [data])

    /** Contenido de modal warning */
    const nameState = renditionState.find(estado => estado.id === Number(data.idState))?.nombre || 'N/A';
    const WarningModalContent = () => {
        return (
            <>
                <br></br>
                <Typography sx={warningGray}>
                    Procederá a modificar el estado para un total de
                </Typography>{" "}<br></br>
                <Typography sx={warningPurpleBold} pb="16px">
                    {selectedRends?.length}
                </Typography>{" "}
                <Typography sx={warningPurple} pb="16px">
                    Registros al estado
                </Typography>{" "}
                <Typography sx={warningPurpleBold} pb="16px" >
                    { nameState }
                </Typography>
                <br></br>{" "}
                <Typography sx={warningGray} >
                    ¿deseas continuar con esta acción?
                </Typography>
            </>
        );
    };
    
    return (
        <>
        {
            loading &&
            <Loading title={"Actualizando estado rendición..."} />
        }
            <CustomModal
                fullWidth
                maxWidth="md"
                open={onOpen}
                onClose={onClose}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <CustomModalTitle id="editSurrender" onClose={onClose} >
                    <Typography sx={modalTitleAlt} pt="16px" pb="4px">
                        {titleEdit}
                    </Typography>
                    <Typography sx={modalSubtitle} textAlign="center" pb="17px">
                        {subTitleEdit}
                    </Typography>
                </CustomModalTitle>

                <DialogContent dividers >
                    <Grid sx={{ margin: "0", width: "100%" }} container>
                        <Grid item xs={6} pr="0 !important">
                            <Typography sx={purpleLittle} pb="4px">
                                Nº rendición
                            </Typography>
                            <Typography sx={blackBold} pt="4px">
                                {(selectedRends?.length || 0) > 0 ? '-' 
                                : data.id}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} pr="0 !important">
                            <Typography sx={purpleLittle} pb="4px">
                                Fecha recepción
                            </Typography>
                            <Typography sx={blackBold} pt="4px">
                                {(selectedRends?.length || 0) > 0 ? '-' 
                                : getFormattedDate(data.renditionDate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} pt="16px">
                            <Typography sx={purpleLittle} pb="4px">
                                RUT prestador
                            </Typography>
                            <Typography sx={blackBold} pt="4px">
                                {(selectedRends?.length || 0) > 0 ? '-'
                                : formatRut(data.rutProvider)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} pt="16px">
                            <Typography sx={purpleLittle} pb="4px">
                                Fecha probable de pago
                            </Typography>
                            <Typography sx={blackBold} pt="4px">
                                {(selectedRends?.length || 0) > 0 ? '-'
                                : getFormattedDate(data.dateProbablePayment)}
                            </Typography>
                        </Grid>
                        {
                            ((selectedRends?.length || 0) > 0) &&
                            <Grid sx={{ margin: "0", width: "100%" }} container>
                                <Grid item xs={6} pt="16px">
                                    <Typography sx={purpleLittle} pb="4px">
                                        Total rendiciones seleccionadas
                                    </Typography>
                                    <Typography sx={blackBold} pt="4px">
                                        {(selectedRends?.length || 0)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} pt="16px">
                                    <Typography sx={purpleLittle} pb="4px">
                                        Monto Total de Selección de Rendiciones
                                    </Typography>
                                    <Typography sx={blackBold} pt="4px">
                                        {formatNumber(suma)}
                                    </Typography>
                                </Grid>
                            </Grid>

                        }

                        <Grid item xs={6} pr="24px !important" pt="18px">
                            <Typography sx={detailText} pb="4px">
                                Estado de la solicitud
                            </Typography>
                            <CustomSelect
                                data-testid='idState'
                                value={data.idState}
                                data={renditionState}
                                titulo={"Seleccione"}
                                name="idState"
                                onChange={(event: { target: any }) => handleFormChange(event.target)}
                            />
                        </Grid>
                        <Grid item xs={6} pt="16px">
                            <Typography sx={detailText} pb="4px">
                                Fecha real de pago
                            </Typography>
                            <LocalizationProvider dateAdapter={DateAdapter} locale={es} >
                                <DatePicker
                                    data-testid='paymentDate'
                                    value={(data.idState===2)? moment(data.paymentDate).startOf('day'): null}
                                    disabled={payDateDisabled}
                                    maxDate={add(new Date(), { days: 30 })}
                                    minDate={convertStringDate(data.renditionDate)}
                                    onChange={(value: any) =>
                                    handleFormChange({ value, name: "paymentDate" })
                                    }
                                    PaperProps={{
                                    className: "paper-calendar",
                                    }}
                                    renderInput={(params: any) => (
                                    <CustomTextField
                                        {...params}
                                        className="full-width"
                                        inputProps={{
                                        ...params.inputProps,
                                        placeholder: "DD/MM/AAAA",
                                        readOnly: true,
                                        }}
                                        error={data.idState === 2 && (data.paymentDate==null || data.paymentDate==='')}
                                        value={(data.idState !==2) ? null : moment(data.paymentDate).startOf('day')}
                                    />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} pr="24px !important" pt="18px">
                            <Typography sx={detailText} pb="4px">
                                Clave referencia pago SAP
                            </Typography>
                            <CustomInput
                                data-testid='SAPPaymentReferenceKey'
                                name={"SAPPaymentReferenceKey"}
                                required={data.idState===1}
                                error={data.idState === 1 && (data.SAPPaymentReferenceKey==null || data.SAPPaymentReferenceKey==='')}
                                id="outlined-error-sappay"
                                disabled={!SAPEnabled}
                                value={data.SAPPaymentReferenceKey}
                                fullWidth
                                onChange={(event: { target: any }) => handleFormChange(event.target)}
                                />
                        </Grid>
                        <Grid item xs={6} pr="0 !important" pt="18px">
                            <Typography sx={detailText} pb="4px">
                            Folio propuesta SAP
                            </Typography>
                            <CustomInput
                                data-testid='SAPProposalFolio'
                                name={"SAPProposalFolio"}
                                required={data.idState===1}
                                error={data.idState===1 && (data.SAPProposalFolio==null || data.SAPProposalFolio==='')}
                                id="outlined-error-sapfol"
                                disabled={!SAPEnabled}
                                value={data.SAPProposalFolio}
                                fullWidth
                                onChange={(event: { target: any }) => handleFormChange(event.target)}
                                />
                        </Grid>
                        <Grid item xs={12} pt="17px">
                            <Typography sx={detailTextB} display={"inline"} pb="6px">
                                Nota resolutiva:{' '}
                            </Typography>
                            <Typography sx={detailText} display={"inline"} pb="6px">
                                Causal de rechazo, escribe o modifica.
                            </Typography>
                            <CustomInput
                                data-testid='note'
                                name={"note"}
                                required={data.idState===3}
                                error={data.idState===3 && (data.note==null || data.note==='')}
                                id="outlined-error-note"
                                value={(data.note)? data.note: ''}
                                fullWidth
                                multiline
                                rows={3}
                                inputProps={{
                                    maxLength: {maxLength}
                                }}
                                onChange={(event: { target: any }) => handleFormChange(event.target)}
                                />
                            <div className="contador">
                                <label className="textoContador"></label>
                                <label className="textoContador">
                                    { data.note ? data.note.length : 0 } / {maxLength}   caracteres
                                </label>
                            </div>
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Button
                        sx={{ p: "15px 52px !important" }}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                     data-testid='btn-guardar'
                        sx={{ p: "15px 52px !important" }}
                        variant="contained"
                        onClick={((selectedRends?.length || 0) > 0) ? () => setOpenWarning(true)
                            : handleNoteRendition}/**Abrir modal warning o update individual */
                        disabled={!buttonEnabled}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </CustomModal>
            
            {openWarning &&
                <WarningModalCustom
                    secondaryButton="Cancelar"
                    primaryButton="Confirmar"
                    contentText={<WarningModalContent />}
                    title="Confirmación"
                    separateButtons
                    headWarning
                    onOpen={openWarning}
                    onClose={handleCloseWarning}
                    loading={spinner} onConfirm={handleNoteMulti}/>
            }
        </>
        
    );
};
