import Box from "@mui/material/Box";
import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { ContentTooltip, StyledTooltipTableEmision } from "../ui-components/Tooltip/customTooltip";
import { returnRolByJerarquia, checkRechazoRol, checkDeleteFile } from "../../utils/rol";
import { numbersWithDots } from "../../utils/index";
import { useGetUser } from "../../hooks/getUser";
import { CustomInput } from "../UI/Input/input";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HeadEmision } from "./headEmision";
import { useTableWithOrder } from "../../hooks/table/useTableWithOrder";
import { stableSort, getComparator } from "../../utils/bonificaciones";
import { format } from "date-fns";
import Toast from "../ui-components/Toast/Toast";
import { useTableDocumentos } from "../../hooks/table/useTableDocumentos";
import { EliminacionModal } from "../ui-components/Modals/modalEliminacion";
import { RechazoModal } from "../ui-components/Modals/modalRechazar";
export const TableEmision = ({ anchorEl, handleClickMenu, handleCloseMenu, files, searchFile }: any) => {
  const {rol,email} = useGetUser()
  const filterListBy = (list: any, filterBy: string, orderBy: string) => {
    const re = new RegExp(filterBy, "i");
    if (orderBy === 'fechaEstado' || orderBy === 'fechaSolicitud') {
      return list?.filter((p: any) => {
        const date = (p[orderBy] ? format(
          new Date(p[orderBy].replace(/T.+/, '').replace(/-/g, '/')),
          "dd-MM-yyyy"
        ) : '');
        return re.test(date);
      });
    }

    return list?.filter((p: any) => {

      if (orderBy === 'rut') {
        return re.test(p.prestador[orderBy]);
      } else {
        return re.test(p[orderBy]);
      }
    });
  }

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    orderColumn,
    handleChangeFilterBy,
    filterList,

    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableWithOrder({
    list: files,
    initialRowsPerPage: 5,
    initialOrder: "desc",
    initialOrderBy: "idNumOperacion",
    filterListBy
  });

  const{
    openAlertTransactionsErrors,
    setOpenAlertTransactionsErrors,
    toastTransactionsErrors,
    handleOnClickGetTransactionsErrors,
    handleOnClickSolicitud,
    handleOnClickGetSAPErrors,
    openModal,
    setOpenModal,
    openModalRechazo,
    setOpenModalRechazo,
    setRechazo,
    rechazo,
    handleOnClickDeleteFile,
    handleOnClickRechazar,
    handleDeleteFile,
    handleRechazar,
    handleOnClickDownloadFile,
    openToastSolicitud,
    setOpenToastSolicitud,
    toastSolicitud,
    disable
  } = useTableDocumentos(searchFile)

  
  
  const isDate = (orderBy: string) => {
    if(orderBy === 'fechaEstado' || orderBy === 'fechaSolicitud' || orderBy === 'periodo'){
      return true
    }else{
      return false
    }
  }
  
  const counter = () => {
    let i = 0
    files.forEach((item: any) => item.impresionPorTope !== null && i++)
    return i
  }

  

  return (
    <>
      {openModal?(<EliminacionModal
                open={openModal}
                setOpen={setOpenModal}
                title="Eliminar Archivo"
                content="¿Estás seguro/a de proceder con la eliminación de este archivo?"

                primaryButton={{
                    text: "Si",
                    onClick: () => {
                      handleDeleteFile()
                    }
                }}

                secondaryButton={{
                    text: "No",
                    onClick: () => {setOpenModal(false)}
                }}
            />): null}

      { openModalRechazo?(<RechazoModal
                open={openModalRechazo}
                setOpen={setOpenModalRechazo}
                title="Rechazar centralización"
                content="¿Rechazar?"
                setRechazo={ setRechazo }
                rechazo= { rechazo }
                email= { email }
                primaryButton={{
                    text: "Si",
                    onClick: () => {
                      handleRechazar()
                    }
                }}

                secondaryButton={{
                    text: "No",
                    onClick: () => {setOpenModalRechazo(false)}
                }}
            />): null} 

      {openAlertTransactionsErrors ? (
        <Toast
          openAlert={openAlertTransactionsErrors}
          setOpenAlert={setOpenAlertTransactionsErrors}
          contentAlert={toastTransactionsErrors}
        />
      ) : null}
      {openToastSolicitud ? (
        <Toast
          openAlert={openToastSolicitud}
          setOpenAlert={setOpenToastSolicitud}
          contentAlert={toastSolicitud}
        />
      ) : null}
      <Box width="100%">
        <Paper className="paper-tables-2">
          <CustomInput
            placeholder={`Filtrar archivos por ${orderColumn}`}
            onChange={(e: any) => handleChangeFilterBy(e.target.value)}
            sx={{ pr: "24px", pl: "24px", pb: "24px" }}
            fullWidth
          />
          <TableContainer sx={{ p: 0 }}>
            <Table sx={{ minWidth: "1500px" }}>
              <HeadEmision
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={counter()}
                
              />

              <TableBody>
                {stableSort(filterList, getComparator(order, orderBy,"",isDate(orderBy)))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => {
                    
                    return (
                      <TableRow hover tabIndex={-1} key={row.idNumOperacion}>
                        
                       
                        <TableCell className="tCell-p">
                          <Typography>{row.periodo !== null?row.periodo.slice(0,7): "" }</Typography>
                        </TableCell>

                        <TableCell className="tCell-p ">
                          <StyledTooltipTableEmision
                              title={<ContentTooltip dataTooltip={row} />}
                              arrow
                            >
                            <Typography>{row.idNumOperacion }</Typography>
                          </StyledTooltipTableEmision>  
                        </TableCell>

                        <TableCell className="tCell-p">
                          <Typography>{row.nombreArchivo}</Typography>
                        </TableCell>

                        <TableCell className="tCell-p">
                          <Typography>
                          {row.fechaSolicitud !== null||""
                                ? format(
                                    new Date(
                                      row.fechaSolicitud
                                        .replace(/T.+/, "")
                                        .replace(/-/g, "/")
                                    ),
                                    "dd-MM-yyyy"
                                  )
                                : ""} 
                          </Typography>
                        </TableCell>
                       
                        <TableCell className="tCell-p">
                          <Typography>
                          { row.nombreArchivo 
                                  ? row.estado 
                                  : row.estado.includes('ARCH. VAL. CON ERROR')
                                    ? 'SOL. VALIDADA CON ERROR'
                                    : row.estado.includes('ARCH. VAL. CON EXITO')
                                      ? 'SOL. VALIDADA CON EXITO' 
                                      : row.estado }
                          </Typography>
                        </TableCell>

                        <TableCell className="tCell-p">
                          <Typography>{row.fechaEstado !== ""
                                ? format(
                                    new Date(
                                      row.fechaEstado
                                        .replace(/T.+/, "")
                                        .replace(/-/g, "/")
                                    ),
                                    "dd-MM-yyyy"
                                  )
                                : ""}</Typography>
                        </TableCell>

                        <TableCell className="tCell-p">
                          <Typography>{row.montoTotal ? numbersWithDots(row.montoTotal.toString()) : " "}</Typography>
                        </TableCell>

                        <TableCell className="tCell-p">
                          <Typography>{row.montoRealPago ? numbersWithDots(row.montoRealPago.toString()) : "0"}</Typography>
                        </TableCell>
                        <TableCell>
                          <MoreVertIcon
                            onClick={(e: any) => handleClickMenu(e, index)}
                          />
                          <Menu
                            id={`menu-${row.idNumOperacion}`}
                            anchorEl={anchorEl[index]}
                            open={Boolean(anchorEl[index])}
                            onClick={() => handleCloseMenu(index)}
                            onClose={() => handleCloseMenu(index)}
                            className="shadow-menu"
                          >
                            <MenuItem
                              disabled={(!row.documentID)}
                              onClick={() => handleOnClickDownloadFile({idRegistro: row.idNumOperacion})}>
                              Descargar Archivo
                            </MenuItem>
                            <MenuItem 
                              disabled={(row.estado !== "ARCH. VAL. CON ERROR")}
                              onClick={() => handleOnClickGetTransactionsErrors({idRegistro: row.idNumOperacion})}>
                              Descargar Movimientos con error
                            </MenuItem>
                            <MenuItem 
                              disabled ={checkDeleteFile(row.estado)}
                              onClick={() => {
                                handleOnClickDeleteFile(row.idNumOperacion)
                                }
                              }>
                              Borrar Archivo
                            </MenuItem>
                            <MenuItem 
                              disabled={!(row.estado === "ARCH. VAL. CON EXITO" && row.tipoItem === "NP") || disable}
                              onClick={() =>{ 
                                handleOnClickSolicitud({idRegistro: row.idNumOperacion, logUsuarioUploader: email})
                                }}>
                              Solicitar Pago
                            </MenuItem>
                            <MenuItem
                              disabled={!(row.estado === "ARCH. VAL. CON EXITO" && row.tipoItem === "CN") || disable} 
                              onClick={() => { 
                                handleOnClickSolicitud({idRegistro: row.idNumOperacion, logUsuarioUploader: email})
                                }
                              }>
                              Centralización Diaria
                            </MenuItem>
                            <MenuItem
                              disabled={(row.estado !== "PROCESADO CON ERRORES")}
                              onClick={() => handleOnClickGetSAPErrors({idRegistro: row.idNumOperacion})}>
                              Descargar asientos con error SAP
                            </MenuItem>
                            <MenuItem
                              disabled={ row.estado === "RECHAZADA" || ( !checkRechazoRol(returnRolByJerarquia(rol),row.logUsuarioCentralizador) || (row.estado !== "PROCESADO CON ERRORES" && row.estado !== "PROCESO CON EXITO"))} 
                              onClick={() => {
                                 handleOnClickRechazar(row.idNumOperacion)

                                }
                              }>
                              Rechazar Solicitud
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                      
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            component="div"
            count={files.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Mostrar por página"}
            sx={{ pt: "24px" }}
            labelDisplayedRows={(e) => {
              return (
                "" +
                (page + 1) +
                " de " +
                Math.ceil(files.length / rowsPerPage)
              );
            }}
          />
        </Paper>
      </Box>
    </>
  );
};
