import * as React from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
import Close from '../../../assets/icons/closeIcon.svg'
const CustomModal = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: "24px",
    },
    '& .MuiDialogTitle-root': {
        paddingTop: "24px",
      },
    '& .MuiDialogActions-root': {
      padding: "24px",
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  
  function CustomModalTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle {...other} sx={{textAlign:"center"}}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <img src={Close} width="18px" alt="close img"/>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  export {
    CustomModal,
    CustomModalTitle
  }