import { Box, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import {
  boxCardNP,
  boxPagesContainer,
} from "../../components/ui-components/box/Boxs";
import { useBulkBonusUpload } from "../../hooks/bulkBonusUpload/useBulkBonusUpload";

export default function BulkBonusUpload() {
  const [folder, setFolder] = useState<string>("");
  const { error, loading, presignedURL, handleFileChange } =
    useBulkBonusUpload();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("cargando archivo");
    fetch(presignedURL ?? "", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: event.currentTarget["csvFile"].files![0],
    })
      .then((response) => {
        console.log("response", response);
        return response.text();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <Box pt="30px" sx={boxPagesContainer} mb="5% !important" role="body">
      <Typography width="86%" m="auto" variant="h4" pb="14px" pt="24px">
        Carga Masiva Bonos
      </Typography>
      <Box sx={boxCardNP} mt="10px !important">
        {loading && <h1>Obteniendo URL</h1>}
        <form onSubmit={handleSubmit} data-testid="uploadForm">
          <label>
            <input type="radio" name="folder" onChange={() => setFolder('insercion')} /> Inserción Masiva
          </label>
          <label>
            <input type="radio" name="folder" onChange={() => setFolder('actualizacion')} /> Actualización Masiva
          </label>
          <br />
          <input
            data-testid="inputFile"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleFileChange(event.target.value, folder)
            }
            type="file"
            name="csvFile"
            required
          />
          <br />
          <button
            type="submit"
            disabled={presignedURL === null}
            data-testid="uploadButton"
          >
            Cargar Archivo Masivo
          </button>
        </form>
        {error && <h1>{error}</h1>}
      </Box>
    </Box>
  );
}
