import { MenuItem, Select } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { CustomSelectInput, menuHoldingStyle, menuItemStyle, menuSelectStyle } from '../../UI/Select/Select';


export const CustomSelect = (props: any) => {
    return (
        <Select
            onChange={props.onChange}
            disabled={props.disabled}
            value={props.value || 0}
            sx={{ width: '100%', textTransform: 'capitalize'}}
            input={<CustomSelectInput className={props.borderColor ? "border-color" : ""} />}
            MenuProps={(props.name ==='holding')? menuHoldingStyle: menuSelectStyle}
            name={props.name}
            IconComponent = {KeyboardArrowDownRoundedIcon}
        >
            <MenuItem disabled key={0} value={0} className="display-none" sx={menuItemStyle}> { props.titulo } </MenuItem>
            {props.data?.map((elem: any, idx: any) => (
                <MenuItem
                    key={idx + 1}
                    value={elem.value}
                    sx={menuItemStyle}
                >
                    {elem.nombre ? elem.nombre : elem}
                </MenuItem>
            ))}
        </Select >
    )
}

