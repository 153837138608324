import { gql } from "@apollo/client";

export const queryObtenerItemsOperacion = gql`
query obtenerItemsOperacion($idTipoParametro: String){
  obtenerItemsOperacion(idTipoParametro : $idTipoParametro){CN{valor1 idCodigo} NP{valor1 idCodigo}}
}`;

export const queryGetFiles = gql`
query getFiles($idItemCentralizador : Int, $tipoItem: String, $rol: String){
    getFiles(idItemCentralizador : $idItemCentralizador, tipoItem : $tipoItem, rol: $rol){
      estado 
      mensaje 
      file{
        periodo
        idNumOperacion
        montoTotal
        montoRealPago
        fechaCarga
        nombreArchivo
        tipoItem
        estado
        fechaEstado
        fechaCargaArchivo
        fechaSolicitud
        totalRegistrosDetalle
        totalAsientosContablesSAP
        totalAsientosOKSAP
        totalAsientosERRORSAP
        logUsuarioCentralizador
        canalOrigen
        documentID
        glosaCausalRechazo
      }
    }
}`;

export const mutationUploadFile = gql`
mutation uploadFile($data : File, $inputFile: String){
    uploadFile(data : $data, inputFile: $inputFile){
      estado
      mensaje
    }
}`;

//actualizar
export const mutationDeleteFile = gql`
mutation deleteFile($idRegistro : Int, $logUsuarioBorrar: String){
    deleteFile(idRegistro : $idRegistro, logUsuarioBorrar: $logUsuarioBorrar){
      estado
      mensaje
    }
}`;

export const queryGetTransactionsErrors = gql`
query getTransactionsErrors($idRegistro:Int){
  getTransactionsErrors(idRegistro:$idRegistro){
    transactionsErrors{
      numPosicion 
      codigoErrorValidador 
      obsCausalError
    }
    estado 
    mensaje
  }
}`;

export const queryGetSAPErrors = gql`
query getSAPErrors($idRegistro:Int){
  getSAPErrors(idRegistro:$idRegistro){
    sapErrors{
      claveControlSAP 
      numPosicion 
      codigoErrorSAP
      descripcionErrorSAP
    }
    estado 
    mensaje
  }
}`;

export const queryDownloadFile = gql`
query downloadFile($idNumOperacion: Int){
  downloadFile(idNumOperacion : $idNumOperacion){
    estado
    mensaje
    file{
      base64
      nombreArchivo
    }
  }
}`;

export const mutationSolicitud = gql`
mutation solicitud($idRegistro : Int, $logUsuarioUploader: String){
    solicitud(idRegistro : $idRegistro, logUsuarioUploader: $logUsuarioUploader){
      estado
      mensaje
    }
}`;

export const mutationRechazar = gql`
mutation rechazar($idRegistro: Int, $logUsuarioRechazo: String, $glosaCausalRechazo: String){
    rechazar(idRegistro : $idRegistro, logUsuarioRechazo: $logUsuarioRechazo, glosaCausalRechazo: $glosaCausalRechazo){
      estado
      mensaje
    }
}`;

export const mutationRollbackStatus = gql`
mutation rollbackStatus($idRegistro: Int!){
  rollbackStatus(idRegistro: $idRegistro){
    estado
    mensaje
  }
}`;