

const getFilterList = (id: number, listAux: any) =>
  listAux.filter((elem: any) => elem.profileId === id);

const isNull = (params: any) => {
  return params != null ? params : "";
}



const isNotUndefinedAndNull = (value: any) => {
  return (value !== null && value !== undefined);
}
const downloadTransactionsErrorsJSON = (errorsArray: any,id:number) => {
  let textData = errorsArray.getTransactionsErrors.transactionsErrors.map((el:any) => {
      return [el.numPosicion, el.codigoErrorValidador, el.obsCausalError].join('|')
  })
  textData = "IDNumFila|CodigoErrorValidador|ObsCausalError \n" + textData.join("\n")
  const blob = new Blob([textData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `LogErrorVal_${id}.txt`;
  link.href = url;
  link.click();
}
const downloadSAPErrorsJSON = (errorsArray: any,id:number) => {
  let textData = errorsArray.getSAPErrors.sapErrors.map((el:any) => {
      return [id,el.claveControlSAP,el.numPosicion, el.codigoErrorSAP, el.descripcionErrorSAP].join('|')
  })
  textData = "IDNumOperacion|IDNumeroAsiento|IDNumFila|CodigoError_SAP|DescripcionError_SAP \n" + textData.join("\n")
  
  const blob = new Blob([textData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `LogErrorAsientosSAP_${id}.txt`;
  link.href = url;
  link.click();
}

const downloadFromBase64 = (base64Str: string, fileName: string, contentType?: string) => {
  const mimetype = (fileName?.includes('.jpg'))? 'image/jpeg' :  ((fileName?.includes('.xlsx'))?'application/octet-stream':'application/pdf');
  //downloadLink.href = `data:${mimetype};base64,${base64Str}`;
  const linkSource = (contentType)? `data:${contentType};base64,${encodeURI(base64Str)}`
  : `data:${mimetype};base64,${base64Str}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

const numbersWithDots = (x: string) => {
  let pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, "$1.$2");
  return x;
}
export {
  getFilterList,
  isNull,
  isNotUndefinedAndNull,
  downloadTransactionsErrorsJSON,
  downloadSAPErrorsJSON,
  downloadFromBase64,
  numbersWithDots
}
