import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client';
import { CustomSelect } from '../../../components/ui-components/customSelect/customSelect';
import { getPrestadoresNoByHolding } from '../../../services/getPrestadores';

interface PrestadorOption {
  id: string;
  nombre: string;
  value: string;
}

export const SelectPrestador = ({holding, handleSelectChange, includeAll }: any) => {

  const [getPrestadores] = useLazyQuery(getPrestadoresNoByHolding);
  const [prestadores, setPrestadores] = useState<PrestadorOption[]>([]);

  const handlePrestadores = () => {
    return new Promise((resolve, reject) => {
      getPrestadores({
        onCompleted: (data) => {
          if (data) {
            const formattedData = data.obtenerPrestadoresNotByHolding.data.map((prestador: any) => ({
              id: prestador.rutPrestador,
              nombre: `${prestador.rutPrestador} ${prestador.nombrePrestador.toUpperCase()}`,
              value: prestador.rutPrestador,
            }));
            const allOption = {
              id: "0",
              nombre: "TODOS",
              value: "todos",
            };
            setPrestadores([allOption, ...formattedData]);
          }
          resolve(data);
        },
        onError: (error) => {
          reject(error);
        }
      });
    });
  }  

  useEffect(() => {
    handlePrestadores();
    //eslint-disable-next-line
  }, []);
    
  return (
    <CustomSelect
        onChange={handleSelectChange}
        disabled={prestadores?.length===0?? false}
        value={holding}
        data={prestadores}
        titulo={"Seleccione un prestador"}
        name="holding"
    />
  )
}
