const interstate = {
    fontFamily: 'Interstate'
}

const titleBox = {
    ...interstate,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.0121em',
    color: "#203442"
}

const textBox = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.0121em',
    color: "#364855"
}

const inputText = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.0121em',
    color: "#364855"
}

const grayText = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.0121em',
    color: "#63717B"
}

const grayTextFile = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.0121em',
    color: "#63717B"
}

const grayBold = {
    ...interstate,
    fontSize: "16px",
    fontWight: 400,
    lineHeight: "16px",
    letterSpacing: " 0.0121em",
    color: "#364855",
};

const backGreen = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.0121em',
    color: "#364855",
    backgroundColor: "#E6F6F5",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    borderRadius: "4px"
}

const greenText700 = {
    ...interstate,
    fontSize: "16px",
    fontWight: 700,
    lineHeight: "16px",
    letterSpacing: " 0.0121em",
    color: "#01A49A",
};

const blackBold = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.0121em',
    color: "#364855",
}

const graySubtext = {
    ...interstate,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '15.86px',
    letterSpacing: '0.0121em',
    color: "#63717B"
}

const altTitle = {
    ...interstate,
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.0121em',
    color: "#364855"
}

const altTitleState = {
    ...interstate,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.0121em',
    color: "#4d449f"
}

const altTitleStateDetails = {
    ...interstate,
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.0121em',
    color: "#000"
}

const littleText = {
    ...interstate,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.0121em',
    color: "#203442"
}

const loader = {
    fontFamily: "Rubik",
    color: '#364855',
    fontSize: '24px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: "0.0120588em",
    pt: "24px"
}

const subLoader = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    pt: "8px"
}

const modalTitle = {
    ...interstate,
    color: '#203442',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}

const modalTitleAlt = {
    ...interstate,
    color: '#203442',
    fontSize: '22px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    textAlign: "center"
}

const modalText = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}

const modalTextBold = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    display: "inline"
}


const boldInputText = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "1.21%",
}

const blueText = {
    ...interstate,
    color: '#5143D5',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
}


const boldGrayModal = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: "1.21%",
}

const grayModal = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
}

const breadcrumStyle = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "14px",
    fontFamily: "interstate",
    marginLeft: "0px",
    paddingLeft: "0px",
};
const breadcrumVisited = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "-1px",
    lineHeight: "24px",
};

const breadcrumMain = {
    paddingBottom: "14px",
    fontFamily: "interstate",
    marginLeft: "0px",
    paddingLeft: "0px",
};

const linkStyle = {
    color: "#364855 !important",
    fontSize: "14px",
    fontWeight: "400",
};

const nowStyle = {
    color: "#364855",
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "-1px",
    lineHeight: "24px",
};

//tooltip


const titleTooltip = {
    marginBottom: '16px',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

const titleRowTooltip = {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

const dataRowTooltip = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#4C4C4C',
    letterSpacing: '0.0120588em'
}

const modalSubtitle = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}

const typoGraphySubtitle = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: "0.0120588em",
    textAlign: "center"
}

const littleGray = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: "0.0120588em", 
}

const purpleLittle = {
    ...interstate,
    color: '#392F95',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: "0.0120588em"
}


const blackBold2 = {
    ...interstate,
    color: '#203442',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '18px',
    letterSpacing: "0.0120588em"
}

const blackBold3 = {
    ...interstate,
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0.0121em",
    color: "#364855",
};

const detailText = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: "0.0021em"
}

const detailTextB = {
    ...interstate,
    color: '#364855',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: "0.0021em",
}
const warningGray = {
    ...interstate,
    color: '#4D5D68',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}

const warningPurple = {
    ...interstate,
    color: '#5143D5',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: "1.21%",
    display: "inline"
}

const warningPurpleBold = {
    ...warningPurple,
    fontWeight: "700"
}


export {
    warningPurpleBold,
    warningPurple,
    warningGray,
    detailTextB,
    detailText,
    blackBold2,
    blackBold3,
    purpleLittle,
    modalSubtitle,
    nowStyle,
    linkStyle,
    breadcrumMain,
    breadcrumVisited,
    breadcrumStyle,
    boldGrayModal,
    grayModal,
    modalTitleAlt,
    blueText,
    boldInputText,
    titleBox,
    textBox,
    inputText,
    grayText,
    grayBold,
    backGreen,
    greenText700,
    grayTextFile,
    graySubtext,
    blackBold,
    altTitle,
    littleText,
    loader,
    subLoader,
    modalTitle,
    modalText,
    modalTextBold,
    titleTooltip,
    titleRowTooltip,
    dataRowTooltip,
    typoGraphySubtitle,
    littleGray,
    altTitleState,
    altTitleStateDetails
}