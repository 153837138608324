import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import escritorioIcon from './../../assets/icons/esct.svg';
import { CustomDrawer, textSelectedDrawer } from "../UI/SideBar/drawer";
import { Link, useLocation } from 'react-router-dom';
import { returnRolByJerarquia } from "../../utils/rol";
import { useGetUser } from "../../hooks/getUser";
import report from './../../assets/icons/reportIconSideBar.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LupaGray from './../../assets/icons/lupa.svg';
import { useState } from "react";
import { grayText } from "../ui-components/Typography/typography";

const items = [
    {
        path: ['/CostosSolPagos/'],
        title: 'Escritorio de Trabajo',
        icon: ExpandMoreIcon,
        disabled: false,
        submenus: [
            {
                path: '/CostosSolPagos',
                title: 'Centralizar/Pagos',
                icon: escritorioIcon
            }
        ]
    }
]

const itemsUP = [
    {
        path: ['/DesktopRendition', '/SolicitudPago'],
        title: 'Escritorio de Trabajo',
        icon: ExpandMoreIcon,
        disabled: false,
        submenus: [
            {
                path: '/DesktopRendition',
                title: 'Bonos Rendidos',
                icon: escritorioIcon
            },
            {
                path: '/SolicitudPago',
                title: 'Solicitud de Pagos',
                icon: escritorioIcon
            }
        ]
    },
    {
        path: ['/ReporteriaRendicion'],
        title: 'Reportería',
        icon: ExpandMoreIcon,
        submenus: [
            {
                path: '/ReporteriaRendicion',
                title: 'Reportería',
                icon: report
            }
        ]
    },
];

const searchRenditions = [
    {
        path: '/consultaMedica',
        title: 'Buscar Rendiciones',
        icon: LupaGray,
    },
];



export const SideBar = ({ open, handleDrawerClose, handleDrawerOpen }: any) => {
    const { rol } = useGetUser();
    const user_rol = returnRolByJerarquia(rol);
    const { pathname } = useLocation();
    const theme = useTheme();
    const menu_title: string = user_rol === 'Unidad de Pago' ? 'Administración Rendiciones' : 'Administración General';
    const menu_items: any[] = user_rol === 'Unidad de Pago' ? itemsUP : items;

    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);


    const handleMenuClick = (path: string) => {
        setSelectedMenu((prev) => (prev === path ? null : path));
    };

    const includePath = (path: string) => {
        return pathname === '/' ? path.toLowerCase().includes('/CostosSolPagos')
            : pathname.toLowerCase().includes(path.toLowerCase());
    }

    return (
        <CustomDrawer
            variant='permanent'
            anchor="left"
            open={open}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            color="inherit"
            sx={{ boxShadow: ' 0px 4px 8px -2px rgba(201, 207, 227, 0.75)' }}
        >

            <Box
                display="flex"
                justifyContent={open ? 'flex-end' : 'center'}
            >
                {open ?
                    <IconButton onClick={handleDrawerClose} sx={{ marginRight: '21px' }}>
                        {theme.direction === 'ltr' ? <ArrowBackRoundedIcon /> : <ArrowForwardRoundedIcon />}
                    </IconButton> :
                    <IconButton onClick={handleDrawerOpen}>
                        <ArrowForwardRoundedIcon />
                    </IconButton>}

            </Box>
            <Box height="30px" pl="16px" pb="14px">
                {open ? <Typography sx={grayText}>{menu_title}</Typography> : null}
            </Box>

            <List>
                {menu_items.map((item: any, i: any) => (
                    <div key={i}>
                        <ListItemButton sx={{ paddingTop: '15px', paddingBottom: '15px' }}
                            onClick={() => handleMenuClick(item.path)}
                            selected={selectedMenu === item.path}
                        >
                            <ListItemIcon sx={{ minWidth: '20%' }}>
                                <ExpandMoreIcon />
                            </ListItemIcon>
                            {open ?
                                <ListItemText
                                    className='linkMenu'
                                    primaryTypographyProps={{}}
                                    primary={item.title}
                                />
                                : null}
                        </ListItemButton>

                        {item.submenus && (
                            <Collapse in={selectedMenu === item.path} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {item.submenus.map((submenu: any, j: number) => (
                                        <Link
                                            key={j}
                                            to={submenu.path}
                                            style={{ textDecoration: 'none'/*, paddingLeft: '30px'*/ }}
                                        >
                                            <ListItem
                                                button
                                                selected={includePath(submenu.path)}
                                                sx={{ backgroundColor: includePath(submenu.path) ? '#CCEDEB' : '' }}
                                            >
                                                <ListItemIcon sx={{ minWidth: '20%' }}>
                                                    <img src={submenu.icon} alt={''} />
                                                </ListItemIcon>
                                                {open ?
                                                    <ListItemText className='linkMenu'
                                                        primaryTypographyProps={includePath(submenu.path) ? textSelectedDrawer : {}}
                                                        primary={submenu.title}
                                                    />
                                                    : null}
                                            </ListItem>
                                        </Link>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </div>
                ))}
            </List>

            {user_rol === 'Unidad de Pago' ?
                <List>
                    {searchRenditions.map((it: any, i: number) => (
                        <div key={i}>
                            <Link key={i} to={it.path} style={{ textDecoration: 'none' }}>
                                <ListItem button selected={selectedMenu === it.path} sx={{ backgroundColor: (selectedMenu === it.path) ? '#CCEDEB' : '', paddingTop: '15px', paddingBottom: '15px' }}
                                    onClick={() => handleMenuClick(it.path)}>
                                    <ListItemIcon sx={{ minWidth: '20%' }}>
                                        <SearchOutlinedIcon />
                                    </ListItemIcon>
                                    {open ?
                                        <ListItemText className='linkMenu'
                                            primaryTypographyProps={(selectedMenu === it.path) ? textSelectedDrawer : {}}
                                            primary={it.title}
                                        />
                                        : null}
                                </ListItem>
                            </Link>
                        </div>
                    ))}
                </List>
                : <span></span>}
        </CustomDrawer>
    )
}
