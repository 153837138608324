import { isNotUndefinedAndNull } from ".";


export function descendingComparator(a: any, b: any, orderBy: any, orderByInObject: string, isDate: boolean) {
    if(isDate === true){
        if(!isNotUndefinedAndNull(b[orderBy]) && !isNotUndefinedAndNull(a[orderBy])){
            return 0
        } else if (formatoFecha(b[orderBy]) < formatoFecha(a[orderBy])) {
            return -1;
        }else if (formatoFecha(b[orderBy]) > formatoFecha(a[orderBy])) {
            return 1;
        }
        return 0
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function descendingComparatorInObject(a: any, b: any, orderBy: any, orderByInObject: string, isDate: boolean) {

    if (!isNotUndefinedAndNull(b[orderByInObject]) && !isNotUndefinedAndNull(a[orderByInObject])) {
        return 0;
    } else if (!isNotUndefinedAndNull(b[orderByInObject])) {
        return -1;
    }else if (!isNotUndefinedAndNull(a[orderByInObject])){
        return 1; 
    }
    if (!isNotUndefinedAndNull(b[orderByInObject][orderBy]) && !isNotUndefinedAndNull(a[orderByInObject][orderBy])) {
        return 0;
    } else if (!isNotUndefinedAndNull(b[orderByInObject][orderBy])) {
        return -1;
    }else if (!isNotUndefinedAndNull(a[orderByInObject][orderBy])){
        return 1; 
    }

    if (b[orderByInObject][orderBy] < a[orderByInObject][orderBy]) {
        return -1;
    }
    if (b[orderByInObject][orderBy] > a[orderByInObject][orderBy]) {
        return 1;
    }

    return 0;
}

function formatoFecha(stringDate: any){

    if(stringDate !== null && stringDate !== "" && stringDate !== undefined){
        const [anio, mes, dia] = stringDate.slice(0,10).split("-")
        return new Date(`${anio}-${Number(mes)}-${dia}`)
    } else {
        return ""
    }
}


//devuelve la funcion para ordenar (desc o asc)
function getComparator(order: any, orderBy: any, orderByInObject: string = '', isDate: boolean = false) {
    const comparator = orderByInObject ? descendingComparatorInObject : descendingComparator;
    
    return order === 'desc'
        ? (a: any, b: any) => comparator(a, b, orderBy, orderByInObject, isDate)
        : (a: any, b: any) => -comparator(a, b, orderBy, orderByInObject, isDate);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array: any, comparator: any) {
    const stabilizedThis = array.map((el: any, index: any) => [el, index]);
    stabilizedThis.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el: any) => el[0]);
}










export {
    getComparator,
    stableSort,
    formatoFecha   
}