
import { Box, Button, Divider, Modal, Typography, TextField } from '@mui/material';

import { styledNormalModal } from '../../UI/Modal/modal';

export const RechazoModal = ({ open, onClose, title, content, primaryButton, secondaryButton, setRechazo, rechazo, email }: any) => {

    
    return (
        <Modal
            open={open}
            // onClose={onClose}
            className="background-modal"
        >
            <Box sx={styledNormalModal}>
                <Box padding="32px 32px">
                    <Typography
                        variant="h3"
                    >
                        {title}
                    </Typography>
                    <Typography pt="24px">
                        {content}
                    </Typography>

                    <TextField fullWidth  id="standard-basic" label="Glosa causal rechazo" variant="standard"
                    
                    
                    onChange={(e) => {
                        setRechazo(() => ({ glosaCausalRechazo: e.target.value,logUsuarioRechazo: email}));
                    }}
                     helperText={(rechazo.glosaCausalRechazo.length > 0 && rechazo.glosaCausalRechazo.length < 10) ? 'Minimo 10 caracteres' : ''} 
                     />
                    

                </Box>

                <Divider />

                <Box
                    padding="24px"
                    display="flex"
                    justifyContent="space-between"
                >   <Button
                        className="medium-button"
                        onClick={primaryButton.onClick}
                        variant="contained"
                        sx={{width:"153px"}}
                        disabled= {rechazo.glosaCausalRechazo.length <= 10}
                    >
                        {primaryButton.text}
                    </Button>
                    {
                        secondaryButton ?
                            <Button
                                className="medium-button"
                                sx={{width:"153px"}}
                                variant="outlined"
                                onClick={secondaryButton.onClick}
                                
                            >
                                {secondaryButton.text}
                            </Button>
                        : null
                    }
                </Box>
            </Box>
        </Modal>
    )
}
