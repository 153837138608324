import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { CostosSolPagos, DesktopRendition, Reporting } from "../pages/pages";
import { Login } from '../pages/login/login';
import { useIsAuthenticated } from "@azure/msal-react";
import { useGetUser } from "../hooks/getUser";
import { returnRolByJerarquia } from "../utils/rol";
import PaymentRequest from "../pages/SolicitudPago/solicitudPago";
import ConsultMedicalReport from "../pages/consultaMedica/ConsultMedicalReport";
import BulkBonusUpload from "../pages/bulkBonusUpload/BulkBonusUpload";

const RoutesConfig = () => {
    const isAuthenticated = useIsAuthenticated();
    const { rol } = useGetUser()
    const user_rol = returnRolByJerarquia(rol)
    return (

        <Suspense fallback={null}>
            <Routes>

                <Route path="/" element={(isAuthenticated) ? user_rol === 'Unidad de Pago' ? <DesktopRendition /> : <CostosSolPagos /> : <Login />} />

                {/* <Route element={<ProtectedRoutes/>}>
                    <Route path="/CostosSolPagos/" element={<CostosSolPagos/>}/>
                    <Route path="/DesktopRendition/" element={<DesktopRendition/>}/>
                </Route> */}

                <Route path="/CostosSolPagos/" element={<CostosSolPagos />} />
                <Route path="/DesktopRendition/" element={<DesktopRendition />} />
                <Route path="/ReporteriaRendicion/" element={<Reporting />} />
                <Route path="/SolicitudPago/" element={<PaymentRequest />} />
                <Route path="/consultaMedica/" element={<ConsultMedicalReport />} />
                <Route path="/cargaMasivaBonos" element={<BulkBonusUpload />} />
            </Routes>
        </Suspense>

    );
};

export default RoutesConfig;
