import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import { issuedStatusStyle, paidStyle, rejectedStatusStyle, requestedStyle } from '../ui-components/box/Boxs'
import { useState } from 'react';
import { renditionState } from '../../utils/const';

export const StatesRendition = ({state}: any) => {
    const [typeState, setTypeState] = useState<any>(null)
    const [textState, setTextState] = useState<string>("")
    const idState = parseInt(state, 10);
    useEffect(() => {
        switch (idState) {
            case 5:
                setTypeState(requestedStyle)
                setTextState("Solicitada")
                break;
            case 2:
                setTypeState(paidStyle)
                setTextState("Pagada")
                break;
            case 3:
                setTypeState(rejectedStatusStyle)
                setTextState("Rechazada")
                break;
            case 1:
                setTypeState(issuedStatusStyle)
                setTextState(renditionState[1].nombre)
                break;
            case 6:
                setTypeState(paidStyle)
                setTextState(renditionState[4].nombre)
                break;
            default:
                break;
        }
        //eslint-disable-next-line
    }, [state])
  return (
    <Box sx={typeState}>
        <Typography variant="h6" fontSize="12px">
            {textState}
        </Typography>
    </Box>
  )
}
