import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext"; 
import { useLocation, Link } from "react-router-dom"; 
import { breadcrumStyle, nowStyle } from "../Typography/typography";

const Breadcrum = ({ back1, link1, now, escritorio, stateInfo }: any) => {
  const location = useLocation();
  let currentLink = "";

  const crumbAlias = (crumb: string) => {
    switch (crumb) {  
      case "Escritorio de trabajo":
        return escritorio; 
      default:
        return crumb.charAt(0).toUpperCase() + crumb.slice(1);
    }
  };

  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;
      return (
        // <MuiLink key={crumb} underline="hover" color="#364855">
          <Link
            to={currentLink}
            state={stateInfo}
            style={{ textDecoration: "underline", color: "#364855 !important" }}
          >
            {crumbAlias(crumb)}
          </Link>
        // </MuiLink>
      );
    });

  return (
    <div role="presentation">
      <Breadcrumbs
        sx={breadcrumStyle}
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
      >
        {/* <MuiLink underline="hover" color="#364855" sx={linkStyle}> */}
          <Link
            to={link1}
            style={{ textDecoration: "underline", color: "#364855 !important" }}
          >
            {back1}
          </Link>
        {/* </MuiLink> */}
        {crumbs.length > 1 && crumbs.slice(0, -1)}
        <Typography sx={nowStyle}>{now}</Typography>
      </Breadcrumbs>
    </div>
  );
};
export default Breadcrum;
