import { EnhancedTableHead } from "../ui-components/Tables/enhancedTableHead";

const headCellsLiquidacion = [
  {
    id: "periodo",
    label: "Período Cont.",
  },
  {
    id: "idNumOperacion",
    label: "Nº Operación",
  },
  {
    id: "nombreArchivo",
    label: "Nombre del archivo",
  },
  {
    id: "fechaSolicitud",
    label: "Fecha Solicitud Centralizacion",
  },
  {
    id: "estado",
    label: "Estado",
  },
  {
    id: "fechaEstado",
    label: "Fecha Estado",
  },
  {
    id: "montoTotal",
    label: "Monto Debe/Haber",
  },
  {
    id: "montoRealPago",
    label: "Monto Real Pago",
  },
  {
    id: "accion",
    label: "Acción", 
  }
];

export const HeadEmision = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  isEmision,
}: any) => {
  return (
    <EnhancedTableHead
      numSelected={numSelected}
      order={order}
      orderBy={orderBy}
      onSelectAllClick={onSelectAllClick}
      onRequestSort={onRequestSort}
      rowCount={rowCount}
      headCells={headCellsLiquidacion}
    > 
    </EnhancedTableHead>
  );
};
