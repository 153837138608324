import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
// import { RetryLink } from "@apollo/client/link/retry";
//import 'cross-fetch/polyfill';
import { setContext } from '@apollo/client/link/context';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { GetAccessToken } from "../utils/azureADTokens";
import fetch from 'cross-fetch';

const abortCon = new AbortController();

// const retryLink = new RetryLink({  
//   delay: {
//     initial: 1300,
//     max: 2,
//     jitter: true
//   },

//   attempts: {
//     max: 2,
//     retryIf: (error, _operation) => !!error
//   }
// });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_URL_CCP,
  fetch: fetch,
  fetchOptions: {
    mode: 'cors',
    signal: abortCon.signal,
  }
});

// const link = ApolloLink.concat(retryLink, httpLink);

const authLink = setContext(async (_, { headers }) => {
  const msalInstance = process.env.JEST_WORKER_ID !== undefined ? '' : new PublicClientApplication(msalConfig);
  // get the authentication token from local storage if it exists
  const token = msalInstance ? await GetAccessToken(msalInstance) : '';  // sessionStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
  }
});
