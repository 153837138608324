import { Box } from '@mui/material'

export const DetailsTooltip = ({provider, totalDocTrib, totalVoucher, canal}: any) => {
  return (
    <Box>
        Prestador: {provider} <br></br>
        Total Doc de cobro: {totalDocTrib} <br></br>
        Total bonos: {totalVoucher}<br></br>
        Canal de origen: {canal}
    </Box>
  )
}
