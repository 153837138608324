import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { styleHead } from '../../UI/Table/table';

export const EnhancedTableHead = ({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, children, isEmision = false }: any) => {

    const createSortHandler = (id: any, label: string) => (event: any) => {
        onRequestSort(event, id, label);
    };

    return (
        <TableHead>
            <TableRow>

                
                {headCells.map((headCell: any) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={styleHead}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id, headCell.label)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {children}
            </TableRow>
        </TableHead>
    );
}
