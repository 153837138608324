import { gql } from "@apollo/client"

export const getPaymentRequest = gql`
query paymentRequest($idTypeProcess: Int, $page: Int, $perPage: Int, $orderBy: String, $columnId: String, $searchText: String) {
    paymentRequest(idTypeProcess: $idTypeProcess, page: $page, perPage: $perPage, orderBy: $orderBy, columnId: $columnId, searchText: $searchText){
        estado
        mensaje
        totalRecords
        data {
            idNumOperacion
            totalAsientosOKSAP
            fechaSoli
            horaSoli
            itemOperacion
            estadoSAP
            fechaEstadoSap
            importeDocSAP
            claveReferenciaSAP
            numeroPropuestaSAP
            usuarioCentralizador
            estado
        }
    }
}`;