import { gql } from "@apollo/client"

export const GetReportBonus = gql`
query mngReportInfoBonus($mngReportInfo: mngReportInfo){
    mngReportInfoBonus(mngReportInfo: $mngReportInfo){
        estado
        mensaje
        totalRecords
        data {
            FolioBono
            FechaEmision
            EstadoBono
            FechaEstadoBono
            Rut_Prestador
            RazonSocial_Prestador
            FolioCM
            CanalBono
            LeyUrgencia
            Preventivo
            FolioCuentaMedica
            TipoCobertura
            TipoAtencion
            MontoPrestacion
            MontoBonificado
            MontoCopago
            MontoRendir
            MontoPagar
            IDRendition
            RutHolding
            RazonSocialHolding
            RutFacturador
            ID_EstadoRendicion
            DesEstadoRendicion
            FechaEstadoRendicion
            FechaRecepcion
            FolioDocCobro
            TipoDocCobro
            DescTipoDocCobro
            FechaDocCob
            MontoNeto
            MontoExento
            MontoImpuesto
            MontoTotal
            DiferenciaTotalAjusteSencillo
        }
        
    }
}
`;