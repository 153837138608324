import { Box, CircularProgress, Typography } from "@mui/material"
import { labelSelectTracingP } from "../UI/Box/boxs"

function IsLoading({title}: any) {
  return <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="76%"
  >
    <CircularProgress size="24px" sx={{ color: '#01A49A' }} /> 
    <Typography sx={labelSelectTracingP} pl="10px">{title}</Typography>
  </Box>
}

export default IsLoading