import { useRef } from "react";
import { Typography, Box, Grid, Button } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  boxCardFilter,
  boxCardNormal,
  boxCardStyleCMWhitoutP,
} from "../../components/UI/Box/boxs";
import { ValueSelect } from "../../components/ui-components/valueSelect/valueSelect";
import { PrimaryButton } from "../../components/UI/Button/Button";
import { TableEmision } from "../../components/tableEmision/tableEmision";

import useCostosSolPagos from "../../hooks/costosSolPagos/useCostosSolPagos";
import { EmptyStateMessage } from "../../components/ui-components/message/emptyStateMessage"
import IsLoading from "../../components/isLoading/isLoading";

// const isLoadingComponent = (loading: boolean, text1: string, text2: string) => {
//   return (!loading ?
//       text1 :
//       <Box display="flex">
//           {text2}
//           <CircularProgress size="20px" sx={{ ml: '13px' }} />
//       </Box>)
// }

export default function CostosSolPagos() {
  const fileInput = useRef<HTMLInputElement>(null);
  const {
    
    setFilesTables,
    isDisabledSearch,
    isDisabledUpload,
    selectedValues,
    anchorEl,
    loading,
    handleClickMenu,
    handleCloseMenu,
    handleOnChangeSelectTipoOp,
    handleOnChangeSelectItemOp,
    handleOnClickSearchFile,
    handleOnClickRefreshFile,
    isDisabledRefresh,
    noResults,
    fillSelects,
    fillSelectsItemOperacion,
    somethingIsLoading,
    onChangeFile,
    filesTable,
    } = useCostosSolPagos()

  return (
    <>
      

      <Box>
        <CssBaseline>
          <Box sx={boxCardNormal}>
            <Typography pt={7} pb={4} variant="h2">
              Centralización de Costos y Solicitudes de Pago
            </Typography>
          </Box>
          <Box sx={boxCardFilter}>
            <Typography pt={4} pb={4} variant="h5">
              Buscar Centralizaciones o Solicitudes de Pago
            </Typography>
            <Grid container>
              <Grid item xs={3} pr="24px">
                <Typography variant="h6" pb="6px">
                  Tipo de Operación
                </Typography>
                <ValueSelect
                  defaultValue={0}
                  name={"tipoOperacion"}
                  titulo={"Seleccione un Tipo"}
                  data={fillSelects()}
                  onChange={handleOnChangeSelectTipoOp}
                  value={selectedValues.tipoOperacion}/>
              </Grid>
              <Grid item xs={3} pr="24px">
                <Typography variant="h6" pb="6px">
                  Item de Operación
                </Typography>
                <ValueSelect
                  defaultValue={0}
                  name={"itemOperacion"}
                  titulo={"Seleccione un Item"}
                  data={fillSelectsItemOperacion()}
                  onChange={handleOnChangeSelectItemOp}
                  value={selectedValues.itemOperacion}
                  />
              </Grid>
              <Grid item xs={3} pr="24px">
                
              </Grid>
              <Grid item xs={3} pr="24px" pb="2px" display="flex" alignItems="end" gap ={1}>
                <Button sx={{ height: 54 }} fullWidth disabled={isDisabledSearch} onClick={handleOnClickSearchFile} variant="contained">
                  Buscar
                </Button>
                <input
                ref={fileInput}
                type="file"
                name="files"
                onChange={onChangeFile}
                onClick={(event: any) => { event.target.value = null }}
                style={{ visibility: "hidden", display: "none" }}
                accept=".xlsx, .xls"
                data-testid="upInput"
                />
                <Button sx={{ height: 54 }} fullWidth disabled={isDisabledUpload}  onClick={() => !somethingIsLoading() ? fileInput.current?.click() : null}  variant="contained">
                  Cargar Archivo
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box sx={boxCardStyleCMWhitoutP}>
            <Box display="flex" justifyContent="space-between" p="16px">
              <Typography p="8px" variant="h5">
                Historial de Centralizaciones y Solicitudes de Pago
              </Typography>
              <PrimaryButton disabled={isDisabledRefresh}  onClick={handleOnClickRefreshFile}>
                Actualizar Datos
              </PrimaryButton>
            </Box>
            {loading ? <IsLoading title={"Cargando archivos"}/> : filesTable.length > 0
              ? (
                <TableEmision
                  anchorEl={anchorEl}
                  handleClickMenu={handleClickMenu}
                  handleCloseMenu={handleCloseMenu}
                  files={filesTable}
                  setCuentasMedicas={setFilesTables}
                  searchFile = {handleOnClickRefreshFile}
                />)
              : noResults === true

                ? (<EmptyStateMessage
                  title={"No se encontraron resultados"}
                  subtitle={"Selecciona otro rango de filtrado para encontrar archivos"}
                  isResolucion={false} />)
                : (<EmptyStateMessage
                  title={"Aún no filtras un tipo de Operación"}
                  subtitle={"Selecciona un tipo e ítem de operación"}
                  isResolucion={false} />)}
          </Box>
        </CssBaseline>
      </Box>
    </>
  );
}
