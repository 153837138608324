import { useState, useEffect } from "react"
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { useGetUser } from "../getUser";
import { returnID, returnRolByJerarquia } from "../../utils/rol";
import { queryObtenerItemsOperacion, mutationUploadFile, queryGetFiles } from "../../services/perfilamiento";
import { isNotUndefinedAndNull } from "../../utils";

function useCostosSolPagos(useHookQuery = useQuery) {
  const [isDisabledSearch, setIsDisabledSearch] = useState<boolean>(true);
  const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(true);
  const [isDisabledRefresh, setIsDisabledRefresh] = useState<boolean>(true);
  const [filesTable, setFilesTables] = useState([]);
  const [file, setFile] = useState<any>({});
  const [selectedCuentaMedica, setSelectedCuentaMedica] = useState<any>([]);
  const [noResults, setNoResults] = useState<boolean>(false)
  const [itemsOperacion, setItemsOperacion] = useState<any>({ CN: [] });
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const tipoOperacion = [
    { id: 1, valorParametro: "Centralización" },
    { id: 2, valorParametro: "Solicitud de Pago" },
  ];
  const { rol, email } = useGetUser();
  const [selectedValues, setSelectedValues] = useState({ tipoOperacion: null, itemOperacion: null })
  const [fnGetFiles, { loading }] = useLazyQuery(queryGetFiles, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });
  const { data: dataItemsOperacion } = useHookQuery(queryObtenerItemsOperacion, { fetchPolicy: "no-cache", variables: { idTipoParametro: returnID(rol) } })
  const [anchorEl, setAnchorEl] = useState([null]);
  const [uploadFileService, { loading: loadingUploadFile }] = useMutation(mutationUploadFile);
  const handleClickMenu = (e: any, index: number) => {
    let anchorRef = anchorEl;
    anchorRef[index] = e.currentTarget;
    setAnchorEl([...anchorRef]);
  };

  const handleCloseMenu = (index: number) => {
    let anchorRef = anchorEl;
    anchorRef[index] = null;
    setAnchorEl([...anchorRef]);
  };

  const searchFiles = (getFiles = fnGetFiles) => {
    setSelectedCuentaMedica([])
    //setRefreshIsDisabled(true)
    let itemOperacion = selectedValues.tipoOperacion === 'Centralización' ?
      dataItemsOperacion.obtenerItemsOperacion.CN.find((obj: { valor1: string; }) => obj.valor1 === selectedValues.itemOperacion)
      :
      dataItemsOperacion.obtenerItemsOperacion.NP.find((obj: { valor1: string; }) => obj.valor1 === selectedValues.itemOperacion)
    getFiles({ variables: { idItemCentralizador: itemOperacion.idCodigo, tipoItem: selectedValues.tipoOperacion === "Centralización" ? "CN" : "NP", rol: returnRolByJerarquia(rol).toUpperCase() } })
      .then((response) => {
        const { data: dataResponse, loading: loadingResponse } = response
        if (isNotUndefinedAndNull(dataResponse) && !loadingResponse) {
          if (isNotUndefinedAndNull(dataResponse.getFiles)) {
            if (dataResponse.getFiles.file.length === 0) {
              setFilesTables(dataResponse.getFiles.file)
              setNoResults(true)
              setIsDisabledUpload(false);
            } else {
              setNoResults(false)
              setFilesTables(dataResponse.getFiles.file)
              setIsDisabledUpload(false);
            }
          } else {
            setFilesTables([])
            setNoResults(true)
            setIsDisabledUpload(false);
          }
        }
      })
      .catch((error) => {
        setFilesTables([]);
        setIsDisabledUpload(true);
        //console.log(error)
      })


  }
  const handleOnClickSearchFile = () => {
    searchFiles()
    setIsDisabledRefresh(false)
    setTimeout(() => {
      setIsDisabledRefresh(false);
    }, 5000)
  }
  const handleOnClickRefreshFile = () => {
    searchFiles()
    setIsDisabledRefresh(true)
    setTimeout(() => {
      setIsDisabledRefresh(false);
    }, 5000)

  }
  /* istanbul ignore next */
  const parseBase64 = (file: any) => {
    const reader = new FileReader();
    let base64WithoutPrefix;
    reader.readAsDataURL(file);
    reader.onload = () => {
      const toBase64 = reader.result?.toString();
      base64WithoutPrefix = toBase64!.slice(
        `data:${file.type};base64,`.length
      );
      setFile({ fileName: file.name, base64: base64WithoutPrefix })
      setIsDisabledSearch(true);
      setTimeout(() => {
        setIsDisabledSearch(false);
      }, 5000)

    };
  };
  const onChangeFile = (e: any) => {

    const file = e.target.files[0];
    if (file && (file.type === 'application/vnd.ms-excel' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'text/csv')) {
      setIsLoadingFile(true);
      parseBase64(file);
    } else {
      alert('Ingrese un documento con extensión valida');
    }
  }

  const fillSelects = () => {
    return tipoOperacion.map((item: any) => item.valorParametro)
  }
  const fillSelectsItemOperacion = () => {
    if (selectedValues.tipoOperacion === 'Centralización') {
      return itemsOperacion.CN ? itemsOperacion.CN.map((item: any) => item.valor1) : [];
    }
    if (selectedValues.tipoOperacion === 'Solicitud de Pago') {
      return itemsOperacion.NP ? itemsOperacion.NP.map((item: any) => item.valor1) : [];
    }
  }

  const handleOnChangeSelectTipoOp = (e: any) => {
    setFilesTables([]);
    setSelectedValues(() => ({ itemOperacion: null, tipoOperacion: e.target.value }))
    setIsDisabledUpload(true);
  }

  const handleOnChangeSelectItemOp = (e: any) => {
    setFilesTables([]);
    setSelectedValues((prevState: any) => ({ ...prevState, itemOperacion: e.target.value }))
    setIsDisabledUpload(true);
  }

  const somethingIsLoading = () => {
    return (loadingUploadFile);
  }


  useEffect(() => {
    const { tipoOperacion, itemOperacion } = selectedValues
    if (itemOperacion !== null && tipoOperacion !== null) {
      setIsDisabledSearch(false)
    } else {
      setIsDisabledSearch(true)
    }

  }, [selectedValues])

  useEffect(() => {
    if (dataItemsOperacion !== undefined) {
      if (dataItemsOperacion.obtenerItemsOperacion !== undefined) {
        setItemsOperacion(dataItemsOperacion.obtenerItemsOperacion)
      } else {
        setItemsOperacion({ CN: [] })
      }
    } else {
      setItemsOperacion({ CN: [] })
    }
  }, [dataItemsOperacion])
  useEffect(() => {
    if (file.fileName && file.base64) {
      let itemOperacion = selectedValues.tipoOperacion === 'Centralización' ?
        dataItemsOperacion.obtenerItemsOperacion.CN.find((obj: { valor1: string; }) => obj.valor1 === selectedValues.itemOperacion)
        :
        dataItemsOperacion.obtenerItemsOperacion.NP.find((obj: { valor1: string; }) => obj.valor1 === selectedValues.itemOperacion)
      uploadFileService({
        variables: {
          data: {
            canalOrigen: "MANUAL",
            tipoItem: selectedValues.tipoOperacion === "Centralización" ? "CN" : "NP",
            idItemCentralizador: itemOperacion.idCodigo,
            nombreArchivo: file.fileName,
            rol: returnRolByJerarquia(rol).toUpperCase(),
            email: email
          },
          inputFile: file.base64
        }
      }).then(() => {
        searchFiles()
      }).catch(() => { searchFiles() })

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return {

    tipoOperacion,
    itemsOperacion,
    isDisabledSearch,
    isDisabledUpload,
    selectedValues,
    anchorEl,
    loading,
    handleClickMenu,
    handleCloseMenu,
    handleOnChangeSelectTipoOp,
    handleOnChangeSelectItemOp,
    selectedCuentaMedica,
    setSelectedCuentaMedica,
    noResults,
    fillSelects,
    fillSelectsItemOperacion,
    somethingIsLoading,
    onChangeFile,
    filesTable,
    setFilesTables,
    searchFiles,
    handleOnClickSearchFile,
    handleOnClickRefreshFile,
    isDisabledRefresh,
    isLoadingFile
  }
}

export default useCostosSolPagos
