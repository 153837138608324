import {
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TablePagination,
    TableSortLabel,
    Menu,
    MenuItem,
    IconButton,
    Box,
    Checkbox
} from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTooltip } from "../../components/ui-components/Tooltip/customTooltip";
import { NoResult } from "../../components/ui-components/Tables/noResult";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StatePaymentRequest } from "./statesPaymentRequest";
import { ModalCambioEstado } from "./modalCambioEstado";
import { useLazyQuery } from "@apollo/client";
import { getPaymentRequest } from "../../services/getPaymentRequest";
import { formatNumber } from "../../utils/formatNumber";
import { Loading } from "../isLoading/Loading";
import { defaultRowsPerPage, headCellsPaymentRequest, headExcelCorrectEntries, perPageGlobal, renditionProcessState } from "../../utils/const";
import { DropdownIndicator } from "../ui-components/Tables/dropDownIndicator";
import { queryDownloadFile } from "../../services/perfilamiento";
import { downloadFromBase64 } from "../../utils";
import Toast from "../ui-components/Toast/Toast";
import { downloadFileCorrectEntries } from "../../services/downloadFileCorrectEntries";
import { utils, writeFile } from "xlsx";
import { getFormattedDate } from "../../utils/getFormatDate";
import ButtonSelect from "../medicalRenditions/buttonSelect";
import { normalHead, stickyCol, stickyHead } from "../UI/Table/table";

const TablePaymentRequest = ({ idTypeProcess, refreshData, setSearchTextbox, searchTextbox, }: any) => {

    /* QUERYS */
    const [getPayments] = useLazyQuery(getPaymentRequest);
    const [getCorrectEntries] = useLazyQuery(downloadFileCorrectEntries);
    const [fnDownloadFile] = useLazyQuery(queryDownloadFile, { fetchPolicy: "no-cache", notifyOnNetworkStatusChange: true });

    /* TOAST */
    const [openAlertTransactionsErrors, setOpenAlertTransactionsErrors] = useState<boolean>(false)
    const [toastTransactionsErrors, setToastTransactionsErrors] = useState({ content: "", isSuccess: false, typeAlert: 'top' })

    const [modal, setModal] = useState(false);

    const [datosSAP, setDatosSAP] = useState({
        idNumOperacion: 0,
        estado: 0,
        numeroPropuestaSAP: '',
        claveReferenciaSAP: '',
    });

    const [anchorEl, setAnchorEl] = useState([null]);


    const handleClickMenu = (e: any, index: number) => {
        let anchorRef = anchorEl;
        anchorRef[index] = e.currentTarget;
        setAnchorEl([...anchorRef]);
    };

    const handleCloseMenu = (index: number) => {
        let anchorRef = anchorEl;
        anchorRef[index] = null;
        setAnchorEl([...anchorRef]);
    };

    const [totalRecords, setTotalRecords] = useState(0);
    const [orderBy, setOrderBy] = useState('1');
    const [order, setOrder] = useState('desc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

    const handleChangePage = (_event: any, newPage: any) => {
        setPage(newPage);
        getListPayment(idTypeProcess, newPage, rowsPerPage, orderBy, order)
    };

    const handleChangeRowsPerPage = (event: any) => {
        const perPage = parseInt(event.target.value, 10);
        setPage(0)
        setRowsPerPage(perPage)
        getListPayment(idTypeProcess, page, perPage, orderBy, order)
    };

    const handleSortClick = (columnId: any) => {
        if (orderBy === columnId) {
            let orderMod = order === 'asc' ? 'desc' : 'asc';
            setOrder(orderMod);
            getListPayment(idTypeProcess, page, rowsPerPage, columnId, orderMod, searchTextbox)
        } else {
            setOrderBy(columnId);
            setOrder('asc');
            getListPayment(idTypeProcess, page, rowsPerPage, columnId, 'asc', searchTextbox)
        }
    };

    const getDirection = (columnId: string): "asc" | "desc" | undefined => {
        if (orderBy === columnId) {
            return order as "asc" | "desc";
        }
        return 'asc';
    };

    const [loader, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState<boolean>(false)

    const getListPayment = (id: any, pageInit: number, perPage: number, orderBy: string, typeOrder: string, searchTextbox?: string) => {
        setLoading(true)
        let column: any = headCellsPaymentRequest.find(x => x.id === orderBy)?.columnName;
        return new Promise((resolve, reject) => {
            getPayments({
                variables: {
                    idTypeProcess: id,
                    page: pageInit + 1,
                    perPage: perPage,
                    orderBy: `${orderBy} ${typeOrder}`,
                    columnId: column,
                    searchText: searchTextbox
                },
                onCompleted: (data) => {
                    if (data) {
                        if (data?.paymentRequest.data) {
                            if (data?.paymentRequest.data.length > 0) {
                                setData(data?.paymentRequest?.data);
                                setTotalRecords(data?.paymentRequest?.totalRecords)
                                setError(false)
                            } else {
                                setError(true)
                            }
                        } else {
                            setError(true)
                        }
                    }
                    setLoading(false)
                    resolve(data);
                },
                onError: (error) => {
                    setLoading(false)
                    reject(error);
                }
            });
        });
    }

    useEffect(() => {
        if (idTypeProcess !== "0") {
            setPage(0)
            getListPayment(idTypeProcess, page, rowsPerPage, orderBy, order, searchTextbox)
        }
        setSelectedItems([]);
        setCheckedPayReq({});
        //eslint-disable-next-line
    }, [idTypeProcess, refreshData])


    /** NVO */
    const [checkedPayReq, setCheckedPayReq] = useState<any>({});
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [globalIdState, setGlobalIdState] = useState<string | null>(null);

    const handleChangeCheckBox = ({ value, name, checked }: { value: string; name: string; checked: boolean }) => {
        setCheckedPayReq((pay: any) => ({
            ...pay,
            [name]: checked,
        }));
        const selectedItem: any = data.find((pay: any) => String(pay.idNumOperacion) === name);

        const uniqueId = `${selectedItem.estadoSAP}-${selectedItem.itemOperacion}`;

        if (checked) {// esta chequeado, se podría pushear
            if (selectedItems.length === 0) {
                setGlobalIdState(uniqueId);
                setSelectedItems([selectedItem]);
            }
            else if (selectedItems.some(item => `${item.estadoSAP}-${item.itemOperacion}` === globalIdState)) {
                setSelectedItems(pay => [...pay, selectedItem]);
            }
            else {//no puede insertar estados distintos
                if (selectedItems.length === 0) {
                    setGlobalIdState(null);//reset global idState
                }
            }
        }
        else { // deschequeado, por lo tanto, quitar
            setSelectedItems(pay => pay.filter((pay: any) => String(pay.idNumOperacion) !== name));
        }

    };

    /** reset global idState */
    useEffect(() => {
        if (selectedItems.length === 0) {
            setGlobalIdState(null);
        }
    }, [selectedItems]);
    /** FIN NVO */

    const DetailsTooltip = ({ claveRef, NumProp, usuario, nomOp }: any) => {
        return (
            <Box>
                Clave referencia SAP:{claveRef} <br></br>
                Numero propuesta SAP:  {NumProp} <br></br>
                Usuario: {usuario}<br></br>
            </Box>
        )
    }

    const handleDownloadEntries = (idNumOperacion: any) => {
        setOpenAlertTransactionsErrors(true);
        setToastTransactionsErrors({ content: "Su documento se esta descargando", isSuccess: true, typeAlert: 'top' });

        getCorrectEntries({ variables: { idNumOperacion: idNumOperacion } })
            .then((response: any) => {
                const book = utils.book_new();

                const preData = response.data.downloadFileCorrectEntries.data.map(({ __typename, ...rest }: any) => rest);

                const sheet = utils.json_to_sheet(preData);
                utils.sheet_add_aoa(sheet, [headExcelCorrectEntries], { origin: 'A1' });

                const name = `AsientosCorrectosEnSAP_${idNumOperacion}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Asientos Correctos');
                writeFile(book, name);

                setOpenAlertTransactionsErrors(false);
                setOpenAlertTransactionsErrors(true);
                setToastTransactionsErrors({ content: "Documento descargado", isSuccess: true, typeAlert: 'top' });
            })
            .catch((error) => {
                setOpenAlertTransactionsErrors(false);
                setOpenAlertTransactionsErrors(true);
                setToastTransactionsErrors({ content: "Su documento no se pudo descargar", isSuccess: false, typeAlert: 'top' });
            })
    }

    const handleDownloadOriginalFile = (idNumOperacion: any) => {
        setOpenAlertTransactionsErrors(true);
        setToastTransactionsErrors({ content: "Su documento se esta descargando", isSuccess: true, typeAlert: 'top' });

        fnDownloadFile({ variables: { idNumOperacion: idNumOperacion } })
            .then((response: any) => {
                const { contentType, base64, nombreArchivo } = response.data.downloadFile.file;
                downloadFromBase64(base64, nombreArchivo, contentType);
                setOpenAlertTransactionsErrors(true);
                setToastTransactionsErrors({ content: "Documento descargado", isSuccess: true, typeAlert: 'top' });
            })
            .catch((error) => {
                setOpenAlertTransactionsErrors(true);
                setToastTransactionsErrors({ content: "Su documento no se pudo descargar", isSuccess: false, typeAlert: 'top' });
            })
    }

    const handleModalModifyStatus = (operacion: any) => {
        if (operacion.estado === 8) {
            operacion.estado = 9;
        }

        if (operacion.numeroPropuestaSAP == null) {
            operacion.numeroPropuestaSAP = '';
        }

        if (operacion.claveReferenciaSAP == null) {
            operacion.claveReferenciaSAP = '';
        }
        const nameState = renditionProcessState.find(estado => estado.id === Number(operacion.estado))?.nombre || 'N/A';
        setGlobalIdState(`${nameState}-`);
        setDatosSAP(operacion);
        setModal(true);
    }

    const handleMultiStatus = () => {
        setDatosSAP(prevState => ({
            ...prevState,
            idNumOperacion: prevState.idNumOperacion,
            estado: (selectedItems[0].estado === 8) ? 9 : selectedItems[0].estado,
        }));
        setModal(true);
    }

    return (
        <>
            <ModalCambioEstado
                {...{
                    onOpen: modal, onClose: setModal, datosSAP, setDatosSAP,
                    page, getListPayment, rowsPerPage, orderBy, order,
                    idTypeProcess, setToastTransactionsErrors, setOpenAlertTransactionsErrors,
                    selectedItems, setSelectedItems, setCheckedPayReq, globalIdState
                }}
            />
            {openAlertTransactionsErrors ? (
                <Toast
                    openAlert={openAlertTransactionsErrors}
                    setOpenAlert={setOpenAlertTransactionsErrors}
                    contentAlert={toastTransactionsErrors}
                />
            ) : null}
            <Paper sx={{ padding: "0 !important", boxShadow: "none" }}>
                {loader &&
                    <Loading title={"Actualizando solicitudes de pago..."} />
                }
                {
                    idTypeProcess !== "0" ?
                        <>
                            {
                                !error ?
                                    <TableContainer component={Paper} sx={{ padding: "0 !important" }}>
                                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox" className="tCell-p headerSort-unlined">
                                                        <Checkbox
                                                            color="primary"
                                                            inputProps={{ "aria-label": "select all renditions" }}
                                                        />
                                                    </TableCell>
                                                    {headCellsPaymentRequest.map((headCell) => (

                                                        <TableCell
                                                            key={headCell.id}
                                                            align={'center'}
                                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                                            className={orderBy === headCell.id ? "escritorioTrabajo activeSort" : "escritorioTrabajo headerSort"}
                                                            sx={(headCell.id === '8') ? stickyHead : normalHead}
                                                        >
                                                            {headCell.sorted ?
                                                                <TableSortLabel
                                                                    active={orderBy === headCell.id}
                                                                    direction={getDirection(headCell.id)}
                                                                    onClick={() => handleSortClick(headCell.id)}
                                                                    IconComponent={() => <DropdownIndicator active={orderBy === headCell.id} order={orderBy === headCell.id ? order : 'desc'} />}>
                                                                    {headCell.label}
                                                                </TableSortLabel>
                                                                :
                                                                <span>{headCell.label}</span>
                                                            }
                                                        </TableCell>

                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    data.map((payReq: any, index: any) => (
                                                        <TableRow key={payReq.idNumOperacion} >
                                                            <TableCell
                                                                padding="checkbox" sx={{ padding: "0px !important" }}
                                                                className="tCell-p headerSort-unlined">
                                                                <Checkbox
                                                                    data-testid='checkbox-1'
                                                                    name={String(payReq.idNumOperacion)}
                                                                    value={payReq}
                                                                    color="primary"
                                                                    checked={!!checkedPayReq[payReq.idNumOperacion]}
                                                                    disabled={globalIdState !== null && `${payReq.estadoSAP}-${payReq.itemOperacion}` !== globalIdState}
                                                                    onChange={(event) => handleChangeCheckBox({
                                                                        value: event.target.value,
                                                                        name: event.target.name,
                                                                        checked: event.target.checked,
                                                                    })}
                                                                    inputProps={{ "aria-label": "controlled" }}
                                                                />
                                                            </TableCell>
                                                            {
                                                                payReq.estado === '12' ?
                                                                    <StyledTooltip
                                                                        arrow
                                                                        placement="right"
                                                                        title={
                                                                            <DetailsTooltip claveRef={payReq.claveReferenciaSAP}
                                                                                NumProp={payReq.numeroPropuestaSAP}
                                                                                usuario={payReq.usuarioCentralizador}
                                                                                nomOp={payReq.idNumOperacion} />
                                                                        }
                                                                    >
                                                                        <TableCell component="th" scope="row" data-testid={'tooltip-' + index}>
                                                                            {payReq.idNumOperacion}
                                                                        </TableCell>
                                                                    </StyledTooltip>
                                                                    :
                                                                    <TableCell component="th" scope="row">
                                                                        {payReq.idNumOperacion}
                                                                    </TableCell>
                                                            }

                                                            <TableCell>
                                                                {getFormattedDate(payReq.fechaSoli)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {payReq.horaSoli}
                                                            </TableCell>
                                                            <TableCell>
                                                                {payReq.itemOperacion}
                                                            </TableCell>
                                                            <TableCell>
                                                                <StatePaymentRequest state={payReq.estado} textState={payReq.estadoSAP} />
                                                            </TableCell>
                                                            <TableCell>
                                                                {getFormattedDate(payReq.fechaEstadoSap)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {payReq.totalAsientosOKSAP}
                                                            </TableCell>
                                                            <TableCell sx={stickyCol}>
                                                                {formatNumber(payReq.importeDocSAP)}
                                                            </TableCell>
                                                            <TableCell align="center" className={" BodyAcciones"}>
                                                                <div className={"groupMenu"}>
                                                                    <IconButton data-testid={index === 0 ? 'button-menu' : undefined} onClick={(e: any) => handleClickMenu(e, index)}>
                                                                        <MoreVertIcon />
                                                                    </IconButton>

                                                                    <Menu id={`menu-${payReq.idNumOperacion}`}
                                                                        anchorEl={anchorEl[index]}
                                                                        open={Boolean(anchorEl[index])}
                                                                        onClick={() => handleCloseMenu(index)}
                                                                        onClose={() => handleCloseMenu(index)}
                                                                        className="shadow-menu">
                                                                        <MenuItem onClick={() => handleDownloadOriginalFile(payReq.idNumOperacion)}>
                                                                            Descargar Archivo Original
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleDownloadEntries(payReq.idNumOperacion)}>
                                                                            Descargar Asientos Correctos
                                                                        </MenuItem>
                                                                        {(selectedItems?.length || 0) === 0 && (
                                                                            <MenuItem onClick={() => handleModalModifyStatus(payReq)}>
                                                                                Cambiar Estado
                                                                            </MenuItem>
                                                                        )}
                                                                    </Menu>

                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={perPageGlobal}
                                            component="div"
                                            count={totalRecords}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            sx={{ mt: "14px", mb: "14px" }}
                                            labelRowsPerPage={`Mostrar por página`}
                                            labelDisplayedRows={(e) => {
                                                return (
                                                    "" +
                                                    (page + 1) +
                                                    " de " +
                                                    Math.ceil(totalRecords === 0 ? 0 : totalRecords / rowsPerPage)
                                                );
                                            }}
                                        />
                                        {((selectedItems?.length || 0) > 0 && !modal) &&
                                            <ButtonSelect checkCount={selectedItems?.length || 0} setOpen={handleMultiStatus} />
                                        }
                                    </TableContainer>
                                    :
                                    <NoResult
                                        newFound
                                        titulo={"No se encontró información de solicitudes de pago"}
                                        subtitulo={"Inténtelo nuevamente usando otra información de filtro"} />
                            }
                        </>
                        :
                        <NoResult
                            newDontSearch
                            titulo={"Realiza una búsqueda"}
                            subtitulo={"Usa los filtros de búsqueda para encontrar las rendiciones que desees consultar."} />
                }
            </Paper >
        </>
    )
}

export default TablePaymentRequest;
