import { gql } from "@apollo/client"

export const GetReportMedicalRenditions = gql`
query mngReportInfo($mngReportInfo: mngReportInfo){
    mngReportInfo(mngReportInfo: $mngReportInfo){
        estado
        mensaje
        totalRecords
        data {
            id
            renditionStatus
            desc_estado
            fechaEstado
            note
            renditionDate
            dateProbablePayment
            paymentDate
            rutHolding
            nameHolding
            rutProvider
            nameProvider
            montoTotalFacturado
            montoTotalRendido
            cuadraturaDiff
            totalToPay
            totalVouchers
            SAPPaymentReferenceKey
            SAPProposalFolio
            IDDocCobroTributario
            documentType
            desc_documentType
            documentFolio
            documentDate
            documentNet
            documentExempt
            documentTax
            documentAmount
            canaldeCarga
            leyUrgencia
            Preventivo
            FolioCuentaMedica
            tipoCobertura
            tipoAtencion
            montoPrestacion
            montoBonificacion
            montoCopago
            renditionAmount
            totalMontoPagar
        }
        
    }
}
`;