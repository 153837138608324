

export function formatNumber(number: any){
    try {
        const format = new Intl.NumberFormat("es-CL", { style: 'currency', currency: 'CLP' }).format(number);
        return format.replace("$", "$ ");
    }catch(error) {
        return "$ 0"
    }    
}

export const removeFormatNumber = (number: any) => Number(String(number).replace(/[$ .]/g, ""))