import { Button, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { CustomModal, CustomModalTitle } from "../../components/UI/Modal/customModal";
import { blackBold, detailText, modalTitleAlt, purpleLittle, warningGray, warningPurple, warningPurpleBold } from "../../components/ui-components/Typography/typography";
import { CustomInput } from "../../components/UI/Input/input";
import { useEffect, useState } from "react";
import { client } from "../../configApollo/config";
import { updateStateFile } from "../../services/updateStateFile";
import { Loading } from "../isLoading/Loading";
import { formatNumber } from "../../utils/formatNumber";
import { CustomSelect } from "../ui-components/customSelect/customSelect";
import { renditionProcessState } from "../../utils/const";
import _ from "lodash";
import { WarningModalCustom } from "../medicalRenditions/modalWarning";
import { updateStateFileMultiple } from "../../services/updateStateFileMultiple";
import { useGetUser } from "../../hooks/getUser";

export const ModalCambioEstado = ({
    onOpen,
    onClose,
    datosSAP,
    setDatosSAP,
    page,
    rowsPerPage,
    getListPayment,
    orderBy, 
    idTypeProcess,
    order,
    setToastTransactionsErrors,
    setOpenAlertTransactionsErrors,
    selectedItems,
    setSelectedItems,
    setCheckedPayReq,
    globalIdState
  }: any) => {
    const [loader, setLoading] = useState(false);
    const { idNumOperacion, numeroPropuestaSAP, claveReferenciaSAP } = datosSAP;
    const {email} = useGetUser()

    const [openWarning, setOpenWarning] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const idsArray = selectedItems?.map((pay: any) => Number(pay.idNumOperacion));
    const suma = _.sumBy(selectedItems, (e: any) => { return e.importeDocSAP?? 0 });

    const [estadoInicial, setEstadoInicial] = useState(datosSAP.estado);
    const [estadoSeleccionado, setEstadoSeleccionado] = useState(datosSAP.estado);

    const [hasChanged, setHasChanged] = useState(false);
    const [claveSAP, setClaveReferenciaSAP] = useState('');
    const [numeroSAP, setNumeroPropuestaSAP] = useState('');

    /** Traspaso de estado */
    useEffect(() => {
        setEstadoInicial(datosSAP.estado);
        setEstadoSeleccionado(datosSAP.estado);

        setClaveReferenciaSAP(datosSAP.claveReferenciaSAP);
        setNumeroPropuestaSAP(datosSAP.numeroPropuestaSAP);

    }, [datosSAP]);

   /** Validación estado distinto al original */
    useEffect(() => {
        let camposRequeridosLlenos = true;
        if (estadoSeleccionado === 12) {
            camposRequeridosLlenos = ((numeroSAP.trim() !== '' && claveSAP.trim() !=='') 
            && (numeroSAP.trim() !== null && claveSAP.trim() !==null)
            && (numeroSAP!==numeroPropuestaSAP && claveSAP!==claveReferenciaSAP));
            setHasChanged(camposRequeridosLlenos);
        }
        else{
            const estadoCambiado = estadoSeleccionado !== estadoInicial;
            setHasChanged(estadoCambiado && camposRequeridosLlenos);
        }
        //eslint-disable-next-line
    }, [estadoSeleccionado, estadoInicial, claveSAP, numeroSAP ]);

    const handleClose = () => {
        onClose(false) //equivalente SetOpen
        cleanStringInputs();
    }

    /** Limpiar */
    const cleanStringInputs = () => {
        setDatosSAP({
            idNumOperacion: 0,
            estado: 0,
            numeroPropuestaSAP: '',
            claveReferenciaSAP: '',
        });
        setSelectedItems([]);
        setCheckedPayReq({});

        setHasChanged(false);
        setClaveReferenciaSAP('');
        setNumeroPropuestaSAP('');
    }

    const handleClickModifyState = async () => {
        setLoading(true)
        onClose(false)
        const result: any = await client.mutate({
            mutation: updateStateFile,
            variables: {
                idNumOperacion,
                estado: Number(estadoSeleccionado),
                numeroPropuestaSAP: numeroSAP,
                claveReferenciaSAP: claveSAP,
                logUser: email,
            }
        });
    
        if(result.data.updateStateFile.estado === 200) {
            setSelectedItems([])
            setLoading(false)
            getListPayment(idTypeProcess, page, rowsPerPage, orderBy, order)
            setOpenAlertTransactionsErrors(true);
            handleClose();
            setToastTransactionsErrors({content: "Operación actualizada correctamente.", isSuccess: true});
        }else {
            setSelectedItems([])
            setLoading(false)
            setOpenAlertTransactionsErrors(true);
            handleClose();
            setToastTransactionsErrors({content: "No se pudo actualizar la operación, favor intentar más tarde.", isSuccess: false});
        }
    }

    const handleModifyStateMulti = async () => {
        setSpinner(true);
        const result: any = await client.mutate({
            mutation: updateStateFileMultiple,
            variables: {
                idNumOperacion: idsArray,
                numeroPropuestaSAP: numeroSAP,
                claveReferenciaSAP: claveSAP,
                estado: Number(estadoSeleccionado),
                logUser: email,
            }
        });
    
        if(result.data.updateStateFileMultiple.estado === 200) {
            setSpinner(false);
            getListPayment(idTypeProcess, page, rowsPerPage, orderBy, order)
            setOpenAlertTransactionsErrors(true);
            setToastTransactionsErrors({content: "Los cambios han sido guardados exitosamente", isSuccess: true});
        }else {
            setSpinner(false);
            setOpenAlertTransactionsErrors(true);
            setToastTransactionsErrors({content: "No se pudo actualizar la operación, favor intentar más tarde.", isSuccess: false});
        }
        setOpenWarning(false)
        handleClose()
    }

    /** Seteo de campos SAP */
    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "claveReferenciaSAP") {
            setClaveReferenciaSAP(value);
        } else if (name === "numeroPropuestaSAP") {
            setNumeroPropuestaSAP(value);
        }
    };

    /** Seteo estado con customSelect */
    const handleSelectChange = (event: any) => { 
        setClaveReferenciaSAP('');
        setNumeroPropuestaSAP('');
        const { value } = event.target;
        setEstadoSeleccionado(value);
    };

    /** Contenido de modal warning */
    const nameState = renditionProcessState.find(estado => estado.id === Number(estadoSeleccionado))?.nombre || 'N/A';
    const WarningModalContent = () => {
        return (
            <>
                <br></br>
                <Typography sx={warningGray}>
                    Procederá a modificar el estado para un total de
                </Typography>{" "}<br></br>
                <Typography sx={warningPurpleBold} pb="16px">
                    {selectedItems?.length}
                </Typography>{" "}
                <Typography sx={warningPurple} pb="16px">
                    Registros al estado
                </Typography>{" "}
                <Typography sx={warningPurpleBold} pb="16px" >
                    { nameState }
                </Typography>
                <br></br>{" "}
                <Typography sx={warningGray} >
                    ¿deseas continuar con esta acción?
                </Typography>
            </>
        );
    };

    const handleCloseWarning = () => {
        setOpenWarning(false);
    };
    
    return (
        <>
            { loader &&
                <Loading title={"Actualizando Operación..."} />
            }
            <CustomModal
                fullWidth
                maxWidth="sm"
                open={onOpen}
                onClose={onClose}
                sx={{
                    "& .MuiBackdrop-root": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <CustomModalTitle id="editOP" onClose={handleClose} >
                    <Typography sx={modalTitleAlt} pt="16px" pb="4px">
                        Cambio de estado
                    </Typography>
                </CustomModalTitle>

                <DialogContent dividers>
                    {((selectedItems?.length || 0) > 0) &&
                        <Grid sx={{ margin: "0", width: "100%" }} container>
                            <Grid item xs={6} pr="24px !important" pt="18px">
                                <Typography sx={purpleLittle} pb="4px">
                                    Total de OP Seleccionadas
                                </Typography>
                                <Typography sx={blackBold} pt="4px">
                                    {(selectedItems?.length || 0)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} pr="0 !important" pt="18px">
                                <Typography sx={purpleLittle} pb="4px">
                                    Monto Total de Selección de OP de Pago
                                </Typography>
                                <Typography sx={blackBold} pt="4px">
                                    {formatNumber(suma)}
                                </Typography>
                            </Grid>
                        </Grid>}

                    <Grid sx={{ margin: "0", width: "100%" }} container>
                        <Grid item xs={12} pt="18px">
                            <Typography sx={detailText} pb="4px">
                                Estado
                            </Typography>
                            <CustomSelect
                                data-testid='select-estado'
                                value={ estadoSeleccionado}
                                data={renditionProcessState}
                                titulo={"Seleccione"}
                                name="estado"
                                onChange={handleSelectChange}
                            />
                        </Grid>       

                        <Grid item xs={6} pr="24px !important" pt="18px">
                            <Typography sx={detailText} pb="4px">
                                Clave de referencia SAP
                            </Typography>
                            <CustomInput
                                data-testid='claveReferenciaSAP'
                                fullWidth
                                disabled={estadoSeleccionado !== 12}
                                placeholder="Ingresa un valor"
                                name="claveReferenciaSAP"
                                value={claveSAP}
                                onChange={handleInputChange}
                                error={(estadoSeleccionado === 12 && (claveSAP===null || claveSAP==='') )}
                            />
                        </Grid>
                        <Grid item xs={6} pr="0 !important" pt="18px">
                            <Typography sx={detailText} pb="4px">
                                Nº de propuesta SAP
                            </Typography>
                            <CustomInput
                                data-testid='numeroPropuestaSAP'
                                fullWidth
                                disabled={estadoSeleccionado !== 12}
                                placeholder="Ingresa un valor"
                                name="numeroPropuestaSAP"
                                value={numeroSAP}
                                onChange={handleInputChange}
                                error={(estadoSeleccionado === 12 && (numeroSAP===null || numeroSAP===''))}
                            />
                        </Grid>
                        <Grid item xs={12} pt="10%">

                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions sx={{ justifyContent: "space-between" }}>
                    <Button
                        sx={{ p: "15px 52px !important" }}
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        sx={{ p: "15px 52px !important" }}
                        variant="contained"
                        onClick={((selectedItems?.length || 0) > 0) ? () => setOpenWarning(true)
                            : handleClickModifyState}/**Abrir modal warning o update individual */
                        disabled={!hasChanged || (estadoSeleccionado===12 && (!numeroSAP || !claveSAP)) }
                    >
                       Guardar cambios
                    </Button>
                </DialogActions>
            </CustomModal>

            {openWarning &&
                <WarningModalCustom
                    secondaryButton="Cancelar"
                    primaryButton="Confirmar"
                    contentText={<WarningModalContent />}
                    title="Confirmación"
                    separateButtons
                    headWarning
                    onOpen={openWarning}
                    onClose={handleCloseWarning}
                    loading={spinner} onConfirm={handleModifyStateMulti}/>
            }
        </>
    );
};
