import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { LogoutSession } from "../../services/logoutSession";
import { client } from "../../configApollo/config";

async function handleLogout(instance : any) {
    const result = await client.mutate({
        mutation: LogoutSession
      });
    
      const {
        estado
      }: {
        estado: number;
      } = result.data.logoutSession;
    
      if(estado === 200) {
        instance.logoutRedirect().catch((e : any) => {
            console.error(e);
        });
    }
}

export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <Button 
            variant="contained"
            onClick={() => handleLogout(instance)}
            disableRipple
            sx={{ boxShadow:'none'}}>
            Cerrar Sesión
        </Button>
    );
}