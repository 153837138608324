import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { blackBold2, detailText, littleGray, textBox, titleBox } from "../../components/ui-components/Typography/typography";
import { boxCardNP } from "../../components/ui-components/box/Boxs";
import Breadcrum from "../../components/ui-components/breadcrum/customBreadcrum";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import es from "date-fns/locale/es";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { CustomTextField } from "../../theme/ui/textField";
import ReportIcon from "../../assets/icons/reportIcon.svg";
import PayIcon from "../../assets/icons/payIcon.svg";
import { GetReportMedicalRenditions } from "../../services/getReportMedicalRendition";
import { headExcelReport } from "../../utils/const";
import { useState, useEffect } from 'react';
import { getFormattedDate, getFormattedddMMyyyy, validateDateByMonths } from "../../utils/getFormatDate";
import Toast from "../../components/ui-components/Toast/Toast";
import {utils, writeFile} from 'xlsx'
import { client } from "../../configApollo/config";
import { GetReportBonus } from "../../services/getReportBonus";
export default function Reporting() {
    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [disabled, setDisabled] = useState<boolean>(true)
    const [alert, setAlert] = useState(false);
    const [contentAlert, setContentAlert] = useState<any>("");
    
    const [spinner, setSpinner] = useState(false);
    const [loader, setLoader] = useState(false);

    /**
     * Genera y descarga Reportes de gestión
     */
    const handleExcelGenerate = async () => {
        try {
            const data: any = await handleRefreshRenditions(1, [])
            console.log(data)
            if(!data) {
                openToast(
                    false,                
                    "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                    "top",
                    { marginTop: "100px" }
                );
            }else { 
                const book = utils.book_new();
                const sheet = utils.json_to_sheet(data);
                utils.sheet_add_aoa(sheet, [headExcelReport], { origin: 'A1' });
                
                const name = `ReporteGestion_${getFormattedddMMyyyy(dateRange.startDate)}_${getFormattedddMMyyyy(dateRange.endDate)}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Reporte Gestión');
                writeFile(book, name);
                openToast(
                    true,
                    "Descarga del reporte exitosa. Acceder al reporte en carpeta de Descargas de su sistema local.",
                    "top",
                    { marginTop: "100px" }
                );
            }
            setLoader(false);
        }catch(error) {
            setLoader(false);
            openToast(
                false,                
                "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                "top",
                { marginTop: "100px" }
            );
        }
    }
    
    /**
     * Genera y descarga Reporte histórico de bonos
     */
    const handleExcelGenerateBonos = async () => {
        try {
            const data: any = await handleRefreshRenditionsBonos(1, [])            
            if(!data) {
                setSpinner(false);
                openToast(
                    false,                
                    "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                    "top",
                    { marginTop: "100px" }
                );
            }else { 
                setSpinner(false);
                const book = utils.book_new();
                const sheet = utils.json_to_sheet(data);
        
                const name = `ReporteHistBonos_${getFormattedddMMyyyy(dateRange.startDate)}_${getFormattedddMMyyyy(dateRange.endDate)}.xlsx`;
                utils.book_append_sheet(book, sheet, 'Reporte Bonos');
                writeFile(book, name);
                openToast(
                    true,
                    "Descarga del reporte exitosa. Acceder al reporte en carpeta de Descargas de su sistema local.",
                    "top",
                    { marginTop: "100px" }
                );
            }
        }catch(error) {
            //console.log(error)
            setSpinner(false);
            openToast(
                false,                
                "Se ha detectado un error al descargar su Reporte. Favor reintentar mas tarde.",
                "top",
                { marginTop: "100px" }
            );
        }        
    }

    const handleRefreshRenditions = async (page: number, allData = []) => {
        setLoader(true);
        return new Promise(async (resolve, reject) => {
            try {
                const result = await client.query({
                    query: GetReportMedicalRenditions,
                    variables: {
                        mngReportInfo: {
                        fechaInicio: getFormattedDate(dateRange.startDate),
                        fechaFin: getFormattedDate(dateRange.endDate), 
                        pagina: page,
                        limit: 1000
                        },
                    },
                });
                const { data } = result.data.mngReportInfo;
                
                if (data) {
                    const preData = data.map(({ __typename, ...rest }: any) => rest);
                    let newData = allData.concat(preData);
                    if (data.length === 1000) {
                        handleRefreshRenditions(page + 1, newData).then(resolve)
                    } else {
                        resolve(newData);
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    };

    const handleRefreshRenditionsBonos = async (page: number, allData = []) => {
        setSpinner(true);
        return new Promise(async (resolve, reject) => {
            try {
                const result = await client.query({
                    query: GetReportBonus,
                    variables: {
                        mngReportInfo: {
                        fechaInicio: getFormattedDate(dateRange.startDate),
                        fechaFin: getFormattedDate(dateRange.endDate), 
                        pagina: page,
                        limit: 5000
                        },
                    },
                });
                
                const { data } = result.data.mngReportInfoBonus;
                
                if (data) {
                    const preData = data.map(({ __typename, ...rest }: any) => rest);                    
                    let newData = allData.concat(preData);
                    if (data.length === 5000) {
                        handleRefreshRenditionsBonos(page + 1, newData).then(resolve)
                    } else {
                        resolve(newData);
                    }
                }
            } catch (error) {
                reject(error)
            }
        })
    };

    const handleFormChange = ({ value, name }: any) => {   
        if (name === "startDate") {
            setDateRange({
                startDate: value,
                endDate: null
            });
        }
        else {
            setDateRange({
                ...dateRange,
                [name]: value
            });
        }
    }

    useEffect(() => {
        if(dateRange.startDate != null && dateRange.endDate != null) {
            if( validateDateByMonths(dateRange.startDate, dateRange.endDate, 12) ) {
                setDisabled(false)
                setAlert(false)
                setContentAlert("")
            }
            else {
                setDisabled(true)
                openToast(
                    false,
                    "Las fechas no puede tener más de 12 meses de diferencia",
                    "top",
                    { marginTop: "100px" }
                );
            }
        }else {
            setDisabled(true)
            setAlert(false)
        }
    }, [dateRange])

    const openToast = (
        isSuccess: boolean,
        content: string,
        typeAlert: string,
        styleAlert: object
      ) => {
        setContentAlert({ isSuccess, content, typeAlert, styleAlert });
        setAlert(true);
    };

    return (
        <>
            <Toast
                openAlert={alert}
                setOpenAlert={setAlert}
                contentAlert={contentAlert}
            />
            <Box pt="30px" mb="5% !important" role="body">
                <Typography width="86%" m="auto" variant="h4" pb="14px" pt="24px">
                    Reporte de rendiciones médicas
                </Typography>
                <Box width="86%" m="auto">
                    <Breadcrum
                        back1="Inicio"
                        link1="/"
                        now="Reportería"
                    />
                </Box>

                <Box sx={boxCardNP} mt="10px !important">
                    <Grid container p="24px">
                        <Grid item xs={12} pr="5%" pb="16px">
                            <Typography sx={titleBox}>Filtrar por rango de fechas</Typography>
                        </Grid>
                        <Grid item xs={6} pt="16px" pr="39px">
                            <Typography sx={detailText} pb="4px">
                                Fecha desde
                            </Typography>
                            <LocalizationProvider dateAdapter={DateAdapter} locale={es} >
                                <DatePicker
                                    value={dateRange.startDate}
                                    PaperProps={{
                                        className: "paper-calendar",
                                    }}
                                    maxDate={new Date()}
                                    onChange={(value: any) =>  handleFormChange({ value, name: "startDate" })}
                                    renderInput={(params: any) => (
                                        <CustomTextField
                                            {...params}
                                            className="full-width"
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "DD/MM/AAAA",
                                                readOnly: true,
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                            <Grid item xs={6} pt="16px">
                                <Typography sx={detailText} pb="4px">
                                    Fecha hasta
                                </Typography>
                                <LocalizationProvider dateAdapter={DateAdapter} locale={es} >
                                    <DatePicker
                                        value={dateRange.endDate}
                                        PaperProps={{
                                            className: "paper-calendar",
                                        }}
                                        disabled={dateRange.startDate == null ? true : false}
                                        onChange={(value: any) =>  handleFormChange({ value, name: "endDate" })}
                                        maxDate={new Date()}
                                        minDate={dateRange.startDate}
                                        renderInput={(params: any) => (
                                            <CustomTextField
                                                {...params}
                                                className="full-width"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "DD/MM/AAAA",
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                    </Grid>
                    <Divider />
                    <Grid container p="24px" style={disabled ? { opacity: 0.5 } : {}}>
                        <Grid item xs={12} pr="5%" pb="16px">
                            <Typography sx={titleBox}>Reporte</Typography>
                            <Typography sx={textBox}>Seleccione un reporte</Typography>
                        </Grid>
                        <Grid item xs={6} pt="16px" pr="39px">
                            <Box className="reportCard" pt="22px">
                                <img src={PayIcon} alt="pay incon"/>
                                <Typography sx={blackBold2} pt="16px">Historial de bonos</Typography>
                                <Typography sx={littleGray} pt="12px" pb="24px">Reporte de bonos históricos, por fecha de atención del bono. Correspondientes a solicitudes ya gestionadas o no por  Prestadores.</Typography>
                                {spinner ? (
                                    <Button data-testid='btnBonos' variant="outlined" sx={{ minWidth: "7rem", height: "50px", }}>
                                        <CircularProgress color="inherit" size={"20px"} />
                                    </Button>
                                ) : (
                                    <Button variant="outlined" disabled={disabled} onClick={handleExcelGenerateBonos}>
                                        Descargar
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={6} pt="16px" pr="39px">
                            <Box className="reportCard">
                                <img src={ReportIcon} alt="report icon"/>
                                <Typography sx={blackBold2} pt="16px">Reportes de gestión</Typography>
                                <Typography sx={littleGray} pt="12px" pb="24px">Reporte de Solicitud para rendición de bonos por fecha de recepción de solicitud. (como máximo 12 meses móviles)</Typography>
                                {loader ? (
                                    <Button variant="outlined" sx={{ minWidth: "7rem", height: "50px", }}>
                                        <CircularProgress color="inherit" size={"20px"} />
                                    </Button>
                                ) : (
                                    <Button data-testid='btnGestion' variant="outlined" disabled={disabled} onClick={() => handleExcelGenerate()}>
                                        Descargar
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}



