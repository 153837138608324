import { Box, CssBaseline, Paper, Typography } from '@mui/material';
import React from 'react'
import { EmptyStateMessage } from '../../components/ui-components/message/emptyStateMessage';

export const Login = () => {
  return (
    <Box className="content">
      <CssBaseline>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography pt={4} pb={4} variant="h2">
            Centralizar Costos/Sol. Pagos
          </Typography>
        </Box>
        <Paper className="paper-tables">
              <EmptyStateMessage
            title="No has iniciado sesión"
            subtitle="Inicia sesión para comenzar"
            />
        </Paper>
      </CssBaseline>
    </Box>
  )
}
